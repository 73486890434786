import FingerprintJS from "@fingerprintjs/fingerprintjs";
const setCookie = (name, value, days) => {
  const expires = new Date();
  expires.setTime(expires.getTime() + days * 24 * 60 * 60 * 1000);
  document.cookie =
    name + "=" + value + ";" + "expires=" + expires.toUTCString() + ";path=/";
};

export const getDeviceId = async () => {
  try {
    // Инициализация объекта FingerprintJS
    const fp = await FingerprintJS.load();

    // Определение уникального идентификатора устройства
    const result = await fp.get();
    const visitorId = result.visitorId;

    // Записываем уникальный идентификатор устройства в куки на 30 дней
    setCookie("deviceId", visitorId, 30);

    return visitorId;
  } catch (error) {
    // console.error({t('error10')}, error);
  }
};
