import React from "react";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";

import { selectIsAuth } from "../../redux/slises/auth";
import Login from "../Login";
import { ThemeContext } from "../Theme/ThemeContext";
import SwitchTheme from "../Theme/SwitchTheme";

function Header() {
  const { t } = useTranslation();
  const isAuth = useSelector(selectIsAuth);
  const { isLightMode, toggleTheme } = React.useContext(ThemeContext);

  return (
    <header>
      <div className="container">
        <div className="left-block">
          <div className="header-logo">
            <Link to="/" className="">
              <i className="icon-logo"></i>
            </Link>
          </div>
        </div>
        <div className="right-block">
          <SwitchTheme isLightMode={isLightMode} toggleTheme={toggleTheme} />
          <div className="account">
            <div className="separator"></div>
            {isAuth ? (
              <Login />
            ) : (
              <>
                <Link to="/account/login" className="button small">
                  {t("btn8")}
                </Link>
              </>
            )}
          </div>
        </div>
      </div>
    </header>
  );
}

export default Header;
