import { useQuery } from "react-query";
import axios from "../axios";

const fetchData = async (updatedArtistToken) => {
  if (!updatedArtistToken) {
    return [];
  }

  try {
    const response = await axios.get(`/orders?userToken=${updatedArtistToken}`);
    return response.data;
  } catch (error) {
    console.error(error);
    return [];
  }
};

export const useFetchData = (updatedArtistToken) => {
  return useQuery("data", () => fetchData(updatedArtistToken), {
    keepPreviousData: true,
    refetchOnWindowFocus: false,
    cacheTime: 600000,
  });
};
