import React from "react";
import moment from "moment";
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation } from "swiper/modules";
import "swiper/css";
import "swiper/css/navigation";

function PeriodSelectors({ selectToData, selectToYear, selectToMonth }) {
  const [activePeriod, setActivePeriod] = React.useState(0);
  const currentYear = moment().year();
  const oldYear = moment().year(moment().year() - 1);
  const oldYearsNumb = moment().subtract(1, "year").year();
  const currentMonth = moment().month();

  const months = [];
  for (let month = 0; month <= currentMonth; month++) {
    const monthName = moment().month(month).format("MMMM");
    const capitalizedMonthName =
      monthName.charAt(0).toUpperCase() + monthName.slice(1);
    const monthNumber = month + 1;
    const monthObj = { name: capitalizedMonthName, number: monthNumber };
    months.push(monthObj);
  }

  months.reverse();

  const previousYear = currentYear - 1; // предыдущий год
  const previousMonth = 11; // последний месяц прошлого года (декабрь)

  const oldMonths = [];
  for (let month = 0; month <= previousMonth; month++) {
    const monthName = moment().year(previousYear).month(month).format("MMMM");
    const capitalizedMonthName =
      monthName.charAt(0).toUpperCase() + monthName.slice(1);
    const monthNumber = month + 1;
    const monthObj = { name: capitalizedMonthName, number: monthNumber };
    oldMonths.push(monthObj);
  }

  oldMonths.reverse();

  const onClickPeriod = (index, year, month, name) => {
    setActivePeriod(index);
    const selectedPeriod = `${year}-${month.toString().padStart(2, "0")}`;
    selectToData(selectedPeriod);
    const selectedYear = `${year}`;
    selectToYear(selectedYear);
    const selectedMonth = `${name.toString().padStart(2, "0")}`;
    selectToMonth(selectedMonth);
  };

  return (
    <div className="head-selection">
      <Swiper
        slidesPerView="auto"
        modules={[Navigation]}
        grabCursor={true}
        spaceBetween={0}
        navigation={{
          prevEl: ".prev-slider",
          nextEl: ".next-slider",
        }}
        className="myswiper"
      >
        {months.map((month, index) => (
          <SwiperSlide
            key={index}
            className={
              activePeriod === index
                ? "head-selection-item active"
                : "head-selection-item"
            }
          >
            <button
              className="head-selection-item-btn"
              data-period={`${currentYear}-${month.number}`}
              onClick={() =>
                onClickPeriod(index, currentYear, month.number, month.name)
              }
            >
              <span className="caption-2">{month.name}</span>
            </button>
          </SwiperSlide>
        ))}

        <SwiperSlide className="head-selection-item disabled">
          <button className="head-selection-item-btn">
            <span className="caption-2">{currentYear}</span>
          </button>
        </SwiperSlide>

        {oldMonths.map((month, index) => (
          <SwiperSlide
            key={index}
            className={
              activePeriod === index + months.length
                ? "head-selection-item active"
                : "head-selection-item"
            }
          >
            <button
              className="head-selection-item-btn"
              data-period={`${oldYearsNumb}-${month.number}`}
              onClick={() =>
                onClickPeriod(
                  index + months.length,
                  oldYearsNumb,
                  month.number,
                  month.name
                )
              }
            >
              <span className="caption-2">{month.name}</span>
            </button>
          </SwiperSlide>
        ))}

        <SwiperSlide className="head-selection-item disabled">
          <button className="head-selection-item-btn">
            <span className="caption-2">{oldYear.format("YYYY")}</span>
          </button>
        </SwiperSlide>

        <div className="prev-slider">
          <i className="icon-arrow-left"></i>
        </div>
        <div className="next-slider">
          <i className="icon-arrow-right"></i>
        </div>
      </Swiper>
    </div>
  );
}

export default PeriodSelectors;
