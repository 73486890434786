import React from "react";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import SwitchLang from "../components/Language";
function Hello() {
  const { t } = useTranslation();

  return (
    <div className="sign">
      <section className="floating-block">
        <SwitchLang />
      </section>
      <section className="auth-block">
        <div className="sign-block">
          <div className="sign-logo">
            <div className="hello-logo"></div>
            <span className="caption-4">{t("title5")}</span>
            <Link to="/" className="">
              <i className="icon-logo"></i>
            </Link>
            <span className="logo-title caption-2">{t("title6")}</span>
          </div>
          <div className="function-block">
            <div className="function-item">
              <div className="icon">
                <i className="icon-merch"></i>
              </div>
              <div className="txt">
                <h3>{t("title7")}</h3>
                <span className="caption-2">{t("text2")}</span>
              </div>
            </div>
            <div className="function-item">
              <div className="icon">
                <i className="icon-dist"></i>
              </div>
              <div className="txt">
                <h3>{t("title8")}</h3>
                <span className="caption-2">{t("text3")}</span>
              </div>
            </div>
            <div className="function-item">
              <div className="icon">
                <i className="icon-integ"></i>
              </div>
              <div className="txt">
                <h3>{t("title9")}</h3>
                <span className="caption-2">{t("text4")}</span>
              </div>
            </div>
          </div>
          <div className="next-block">
            <Link to="/account/login" className="button">
              {t("btn14")}
            </Link>
          </div>
        </div>
      </section>
      <section className="bg-block">
        <div className="bg-sign"></div>
      </section>
    </div>
  );
}

export default Hello;
