import React from "react";
import { NavLink } from "react-router-dom";
import fetchUserInfo from "../User";
import { useTranslation } from "react-i18next";

function Aside({ userRoles }) {
  const blockRef = React.useRef(null);
  const [isSticky, setIsSticky] = React.useState(false);
  const { t } = useTranslation();

  React.useEffect(() => {
    const handleScroll = () => {
      const scrollTop =
        window.pageYOffset || document.documentElement.scrollTop;
      setIsSticky(scrollTop > 106);
    };

    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const classes = isSticky ? "main-menu sticky-aside" : "main-menu";

  return (
    <aside>
      <nav className={classes} ref={blockRef}>
        <div className="menu-item">
          <NavLink
            to="/"
            className={({ isActive }) => (isActive ? "active" : "")}
          >
            <div className="menu-icon">
              <i className="icon-merch"></i>
            </div>
            <span className="button-1">{t("btn")}</span>
          </NavLink>
        </div>
        {userRoles === "Admin" && (
          <>
            <div className="menu-item admin">
              <NavLink
                to="/admin/users"
                className={({ isActive }) => (isActive ? "active" : "")}
              >
                <div className="menu-icon">
                  <i className="icon-users"></i>
                </div>
                <span className="button-1">{t("btn2")}</span>
              </NavLink>
            </div>
            <div className="menu-item admin">
              <NavLink to="/admin/setting">
                <div className="menu-icon">
                  <i className="icon-setting"></i>
                </div>
                <span className="button-1">{t("btn3")}</span>
              </NavLink>
            </div>
          </>
        )}
        {userRoles === "Redactor" && (
          <>
            <div className="menu-item admin">
              <NavLink
                to="/admin/users"
                className={({ isActive }) => (isActive ? "active" : "")}
              >
                <div className="menu-icon">
                  <i className="icon-users"></i>
                </div>
                <span className="button-1">{t("btn2")}</span>
              </NavLink>
            </div>
          </>
        )}
        <div className="menu-item shop">
          <NavLink
            to="https://vsrap.shop/"
            target="_blank"
            rel="noopener noreferrer"
          >
            <div className="menu-icon">
              <i className="icon-vsrap"></i>
            </div>
            <span className="button-1">{t("btn4")}</span>
          </NavLink>
        </div>
        <div className="menu-item disabled">
          <NavLink to="/dist">
            <div className="menu-icon">
              <i className="icon-dist"></i>
            </div>
            <span className="button-1">{t("btn5")}</span>
          </NavLink>
        </div>
        <div className="menu-item disabled">
          <NavLink to="/int">
            <div className="menu-icon">
              <i className="icon-integ"></i>
            </div>
            <span className="button-1">{t("btn6")}</span>
          </NavLink>
        </div>
        {userRoles === "Admin" && (
          <>
            <div className="menu-item admin disabled">
              <NavLink to="/posts">
                <div className="menu-icon">
                  <i className="icon-posts"></i>
                </div>
                <span className="button-1">{t("btn7")}</span>
              </NavLink>
            </div>
          </>
        )}
        {userRoles === "Redactor" && (
          <>
            <div className="menu-item admin disabled">
              <NavLink to="/posts">
                <div className="menu-icon">
                  <i className="icon-posts"></i>
                </div>
                <span className="button-1">{t("btn7")}</span>
              </NavLink>
            </div>
          </>
        )}
      </nav>
    </aside>
  );
}

export default fetchUserInfo(Aside);
