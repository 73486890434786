import React from "react";
import { Link } from "react-router-dom";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import toast, { Toaster } from "react-hot-toast";
import SocialLoginButtons from "../../components/SocialButton";
import { useTranslation } from "react-i18next";
import SwitchLang from "../../components/Language";

import {
  password,
  clearErrorAfterTime,
  clearErrorOnNavigation,
  selectError,
} from "../../redux/slises/auth";

function Password(props) {
  const { t } = useTranslation();
  const [email, setEmail] = React.useState("");
  const error = useSelector(selectError);
  const dispatch = useDispatch();

  React.useEffect(() => {
    return () => {
      toast.remove();
    };
  }, []);

  const {
    register,
    formState: { errors },
  } = useForm({
    defaultValues: {},
    mode: "onBlur",
  });

  React.useEffect(() => {
    if (error) {
      dispatch(clearErrorAfterTime());
      if (error === t("error24")) {
        toast.error(t("error25"));
      } else if (error === t("success5")) {
        toast.success(t("success6"));
      } else {
        toast.error(t("success26"));
      }
    }
  }, [error, dispatch]);

  const handleNavigation = () => {
    dispatch(clearErrorOnNavigation());
  };

  const handleResetPassword = async (e) => {
    e.preventDefault();
    const email = e.target.email.value.toLowerCase();

    dispatch(password(email));
  };
  return (
    <div className="sign">
      <section className="floating-block">
        <SwitchLang />
      </section>
      <Toaster position="bottom-right" reverseOrder={false} />
      <section className="auth-block">
        <div className="sign-block">
          <div className="sign-logo">
            <span className="caption-4">{t("title5")}</span>
            <Link to="/" className="">
              <i className="icon-logo"></i>
            </Link>
          </div>
          <div className="sign-form">
            <div className="sign-form-title">
              <h1>{t("title19")}</h1>
            </div>
            <form
              action="#"
              className="form__form-box form-box"
              onSubmit={handleResetPassword}
            >
              <label>
                <input
                  className={errors?.email && "error"}
                  type="email"
                  value={email}
                  placeholder={t("placeholder11")}
                  {...register("email", {
                    required: t("error18"),
                    pattern: {
                      value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                      message: t("error19"),
                    },
                  })}
                  onChange={(e) => setEmail(e.target.value)}
                />
                <div className="hidden">
                  {errors?.firstName?.email &&
                    toast.error(errors?.email?.message, {
                      toastId: "email-error",
                    })}
                </div>
              </label>
              <button type="submit">{t("btn27")}</button>
            </form>
            <div className="sign-form-link">
              <Link
                to="/account/login"
                onClick={handleNavigation}
                className="button black"
              >
                {t("btn23")}
              </Link>
            </div>
          </div>
          <SocialLoginButtons />
        </div>
      </section>
      <section className="bg-block">
        <div className="bg-sign"></div>
      </section>
    </div>
  );
}

export default Password;
