import React from "react";
import axios from "../../axios";
import { useQuery } from "react-query";
async function fetchSetting() {
  const response = await axios.get(`/setting`);
  return response.data;
}
function fetchSettingOptions(WrappedComponent) {
  return function SettingOptions(props) {
    const { data, isLoading, isError } = useQuery("setting", fetchSetting, {
      keepPreviousData: true,
      refetchOnWindowFocus: false,
    });

    return (
      !isLoading &&
      !isError &&
      data && (
        <WrappedComponent
          settingPercentLow={parseFloat(data.percent_low)}
          settingPercentMiddle={parseFloat(data.percent_middle)}
          settingPercentHigh={parseFloat(data.percent_high)}
          settingTotalLow={parseInt(data.total_low)}
          settingTotalMiddle={parseInt(data.total_middle)}
          {...props}
        />
      )
    );
  };
}

export default fetchSettingOptions;
