const enJSON = {
  title: "VSRAPBOY - Partner's Personal account",
  title2: "Recent orders",
  title3: "Editing a profile",
  title4: "Oh, I think you're lost!",
  title5: "ONLY FOR",
  title6: "Personal account of VSRAP partners and artists",
  title7: "Merchandising",
  title8: "Distribution",
  title9: "Integrations",
  title10: "General settings",
  title11: "Setting up revenue percentages",
  title12: "All users",
  title13: "Profile Editing",
  title14: "SALES REPORTS",
  title15: "PRODUCTS",
  title16: "REVENUE",
  title17: "PROFIT",
  title18: "Account registration",
  title19: "Password recovery",
  title20: "Account activation",
  title21: "Log in account",

  text: "The page is currently unavailable, or you do not have access to this section.",
  text2: "Sales reports, profit calculation",
  text3: "Music release statistics",
  text4: "All advertising analytics",
  text5: "Your account has not been activated yet!",
  text6: "To activate your account, follow the link in the email sent to:",

  developer: "Made with love in",

  btn: "Merchandising",
  btn2: "Users",
  btn3: "Settings",
  btn4: "vsrap.shop",
  btn5: "Distribution",
  btn6: "Integrations",
  btn7: "Posts",
  btn8: "Login",
  btn9: "Log out",
  btn10: "Go back",
  btn11: "Profile Settings",
  btn12: "Save Changes",
  btn13: "Go to the main page",
  btn14: "Next",
  btn15: "All",
  btn16: "Admins",
  btn17: "Editors",
  btn18: "Artists",
  btn19: "Users",
  btn20: "Yes",
  btn21: "No",
  btn22: "Delete account",
  btn23: "Login",
  btn24: "Sign up",
  btn25: "Forgot password?",
  btn26: "Register",
  btn27: "Recover password",
  btn28: "Resend the email",

  label: "E-mail:",
  label2: "ID artist:",
  label3: "First name:",
  label4: "Last name:",
  label5: "Old password:",
  label6: "New password:",
  label7: "Standard percentage of revenue:",
  label8: "Average percentage of revenue:",
  label9: "High percentage of revenue:",
  label10: "Standard Revenue threshold:",
  label11: "High revenue threshold:",
  label12: "Group:",
  label13: "Display revenue block in sales reports",
  label14: "Percentage of revenue:",
  label15: "ID collection:",

  placeholder: "For example: info@mail.ru ",
  placeholder2: "For example: IVANOV",
  placeholder3: "For example: Ivan",
  placeholder4: "For example: Ivanov",
  placeholder5: "",
  placeholder6: "",
  placeholder7: "For example: 20",
  placeholder8: "For example: 22",
  placeholder9: "For example: 25",
  placeholder10: "User search",
  placeholder11: "Your E-mail",
  placeholder12: "Password",
  placeholder13: "Name",
  placeholder14: "Last name",
  placeholder15: "Repeat password",
  placeholder16: "For example: 1500000",
  placeholder17: "For example: 3000000",

  role_admin: "Admin",
  role_editor: "Editor",
  role_artist: "Artist",

  info: "Saving...",
  info2: "Deleting...",
  info3: "Do you want to delete account?",
  info4: "Click on the block to see the revenue",
  info5: "Click on the block to see the profit",

  error: "Avatar not loaded",
  error2: "Avatar not deleted",
  error4: "No changes detected",
  error5: "An error occurred while saving changes",
  error6: "No users found for your query",
  error7: "Failed to get a list of users",
  error8: "An error occurred while deleting the account",
  error10: "Error when getting a unique device ID:",
  error11: "Sales data was not received",
  error12: "Orders for the specified date were not found",
  error13:
    "No login has been made, incorrect account data may have been entered",
  error14: "Enter a name",
  error15: "The name is too long",
  error16: "Enter last name",
  error17: "Last name is too long",
  error18: "Enter an email address",
  error19: "Enter a valid email address",
  error20: "Come up with a password",
  error21: "Password length is at least 6 characters",
  error22: "Re-enter password",
  error23: "Passwords must match",
  error24: "The email has already been sent within the last 5 minutes!",
  error25: "The email is sent once every 5 minutes",
  error26: "The user with this email was not found",

  success: "Avatar loaded!",
  success2: "Avatar deleted!",
  success4: "Changes saved",
  success5: "Password reset link successfully sent!",
  success6: "The password reset link has been sent!",

  order2: "Refund",
  order3: "Retail",
  order4: "pcs",
};

export default enJSON;
