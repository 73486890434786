import React from "react";
import { Link, useNavigate } from "react-router-dom";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import toast, { Toaster } from "react-hot-toast";
import SocialLoginButtons from "../../components/SocialButton";
import { useTranslation } from "react-i18next";
import SwitchLang from "../../components/Language";
import {
  login,
  clearErrorAfterTime,
  clearErrorOnNavigation,
  selectError,
} from "../../redux/slises/auth";

function SignIn(props) {
  const { t } = useTranslation();
  const [showPassword, setShowPassword] = React.useState(false);
  const error = useSelector(selectError);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  React.useEffect(() => {
    return () => {
      toast.remove();
    };
  }, []);

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    defaultValues: {},
    mode: "onBlur",
  });

  React.useEffect(() => {
    if (error) {
      dispatch(clearErrorAfterTime());
      toast.error(t("error13"));
    }
  }, [error, dispatch]);

  const handleNavigation = () => {
    dispatch(clearErrorOnNavigation());
  };

  const onSubmit = async (values) => {
    values.email = values.email.toLowerCase();

    const data = await dispatch(login(values));

    if (!data.payload) {
      return;
    }

    if ("accessToken" in data.payload) {
      window.localStorage.setItem("token", data.payload.accessToken);
      navigate("/");
    }
  };

  const handleTogglePassword = () => {
    setShowPassword((prevShowPassword) => !prevShowPassword);
  };

  return (
    <div className="sign">
      <section className="floating-block">
        <SwitchLang />
      </section>
      <Toaster position="bottom-right" reverseOrder={false} />
      <section className="auth-block">
        <div className="sign-block">
          <div className="sign-logo">
            <span className="caption-4">{t("title5")}</span>
            <Link to="/" className="">
              <i className="icon-logo"></i>
            </Link>
          </div>
          <div className="sign-form">
            {/*<div className="sign-form-title">*/}
            {/*  <h1>Вход в аккаунт</h1>*/}
            {/*</div>*/}
            <form
              onSubmit={handleSubmit(onSubmit)}
              className="form__form-box form-box"
            >
              <label>
                <input
                  className={errors?.email && "error"}
                  type="email"
                  placeholder={t("placeholder11")}
                  {...register("email")}
                />
              </label>
              <label>
                <input
                  className={errors?.password && "error"}
                  type={showPassword ? "text" : "password"}
                  autoComplete="on"
                  placeholder={t("placeholder12")}
                  {...register("password")}
                />
                <span
                  onClick={handleTogglePassword}
                  className="password-toggle"
                >
                  {showPassword ? (
                    <i className="icon-eye-close"></i>
                  ) : (
                    <i className="icon-eye"></i>
                  )}
                </span>
              </label>
              <button type="submit">{t("btn23")}</button>
            </form>
            <div className="sign-form-link">
              <Link
                to="/account/register"
                onClick={handleNavigation}
                className="button black"
              >
                {t("btn24")}
              </Link>
              <Link
                to="/account/forgot-password"
                onClick={handleNavigation}
                className="button black"
              >
                {t("btn25")}
              </Link>
            </div>
          </div>
          <SocialLoginButtons />
        </div>
      </section>
      <section className="bg-block">
        <div className="bg-sign"></div>
      </section>
    </div>
  );
}

export default SignIn;
