import React from "react";

const SocialLoginButtons = () => {
  const handleSocialLogin = (e, provider) => {
    e.preventDefault();
    const newUrl = `${process.env.REACT_APP_API_URL}/auth/${provider}`;
    window.location.href = newUrl;
  };

  const handleVk = (e) => {
    handleSocialLogin(e, "vk");
  };

  const handleGoogle = (e) => {
    handleSocialLogin(e, "google");
  };

  // const handleFb = (e) => {
  //   handleSocialLogin(e, "fb");
  // };

  return (
    <div className="sign-soc">
      <button onClick={handleVk} className="button soc">
        <i className="icon-vk"></i>
      </button>
      {/*<button onClick={handleFb} className="button soc">*/}
      {/*  <i className="icon-fb"></i>*/}
      {/*</button>*/}
      <button onClick={handleGoogle} className="button soc">
        <i className="icon-google"></i>
      </button>
    </div>
  );
};

export default SocialLoginButtons;
