import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import toast, { Toaster } from "react-hot-toast";
import { useTranslation } from "react-i18next";
import Skeleton from "../../components/UsersList/Skeleton";
import UserItem from "../../components/UsersList";
import { ThemeContext } from "../../components/Theme/ThemeContext";
import { fetchUsers } from "../../redux/slises/users";

function Users() {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { isLightMode } = React.useContext(ThemeContext);

  const [search, setSearch] = React.useState("");
  const [activeButton, setActiveButton] = React.useState("");

  const skeleton = [...new Array(12)].map((_, index) => (
    <Skeleton isLightMode={isLightMode} key={index} />
  ));

  React.useEffect(() => {
    dispatch(fetchUsers());
    toast.dismiss();
  }, []);

  const users = useSelector((state) => state.users.items);

  const filteredUsers = users.filter((user) => {
    if (activeButton && String(user.roles) !== activeButton) {
      return false;
    }
    return !(
      search &&
      !user.lastName.toLowerCase().includes(search.toLowerCase()) &&
      !user.firstName.toLowerCase().includes(search.toLowerCase())
    );
  });

  const status = useSelector((state) => state.users.status);
  const isLoading = status === "loading";
  const isError = status === "error";
  const isLoaded = status === "loaded";
  const isNoResults = filteredUsers.length === 0;

  const roleTranslations = {
    Admin: t("role_admin"),
    Redactor: t("role_editor"),
    Artist: t("role_artist"),
  };

  const handleButtonClick = (role) => {
    setActiveButton(role);
  };

  const handleSearch = (phrase) => {
    setSearch(phrase.target.value);
  };

  const handleClearSearch = () => {
    setSearch("");
  };

  return (
    <main>
      <Toaster position="bottom-right" reverseOrder={false} />
      <div className="wrapper">
        <div className="content-users">
          <div className="head-selection">
            <div
              className="head-selection-line dragscroll"
              id="head-selection-line"
            >
              <div
                className={`head-selection-item ${
                  activeButton === "" ? "active" : ""
                }`}
              >
                <Link to="#" onClick={() => handleButtonClick("")}>
                  <span className="caption-2">{t("btn15")}</span>
                </Link>
              </div>
              <div
                className={`head-selection-item ${
                  activeButton === "Admin" ? "active" : ""
                }`}
              >
                <Link to="#" onClick={() => handleButtonClick("Admin")}>
                  <span className="caption-2">{t("btn16")}</span>
                </Link>
              </div>
              <div
                className={`head-selection-item ${
                  activeButton === "Redactor" ? "active" : ""
                }`}
              >
                <Link to="#" onClick={() => handleButtonClick("Redactor")}>
                  <span className="caption-2">{t("btn17")}</span>
                </Link>
              </div>
              <div
                className={`head-selection-item ${
                  activeButton === "Artist" ? "active" : ""
                }`}
              >
                <Link to="#" onClick={() => handleButtonClick("Artist")}>
                  <span className="caption-2">{t("btn18")}</span>
                </Link>
              </div>
              <div className="head-selection-item disabled">
                <Link to="#">
                  <span className="caption-2">{t("btn19")}</span>
                </Link>
              </div>
            </div>
            <div className="search-block">
              <div className="search-block">
                {search && (
                  <div onClick={handleClearSearch} className="remove-btn">
                    <i className="icon-close"></i>
                  </div>
                )}
                <input
                  value={search}
                  onChange={handleSearch}
                  className="search input"
                  placeholder={t("placeholder10")}
                />
                <div className="search-btn">
                  <i className="icon-search"></i>
                </div>
              </div>
            </div>
          </div>
          <h1>{t("title12")}</h1>
          <div className="users">
            <div className="users-block">
              {isLoading ? (
                skeleton
              ) : isError ? (
                <p className="caption-2 info">{t("error7")}</p>
              ) : isNoResults ? (
                <p className="caption-2 info">{t("error6")}</p>
              ) : isLoaded ? (
                filteredUsers.map((user) => (
                  <UserItem
                    key={user._id}
                    userId={user._id}
                    userName={user.firstName + " " + user.lastName}
                    userAvatar={user.avatarURL}
                    userRoles={roleTranslations[user.roles]}
                    userFirstName={user.firstName}
                    userLastName={user.lastName}
                  />
                ))
              ) : (
                <p className="caption-2 info">{t("error7")}</p>
              )}
            </div>
          </div>
        </div>
      </div>
    </main>
  );
}

export default Users;
