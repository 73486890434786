import React from "react";

export const ThemeContext = React.createContext();

export const ThemeProvider = ({ children }) => {
  const [isLightMode, setIsLightMode] = React.useState(
    localStorage.getItem("theme") === "light"
  );

  const setDark = () => {
    localStorage.setItem("theme", "dark");
    document.documentElement.setAttribute("data-theme", "dark");
  };

  const setLight = () => {
    localStorage.setItem("theme", "light");
    document.documentElement.setAttribute("data-theme", "light");
  };

  React.useEffect(() => {
    if (isLightMode) {
      setLight();
    } else {
      setDark();
    }
  }, [isLightMode]);

  return (
    <ThemeContext.Provider
      value={{
        isLightMode,
        toggleTheme: () => setIsLightMode((prevMode) => !prevMode),
      }}
    >
      {children}
    </ThemeContext.Provider>
  );
};
