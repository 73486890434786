import React from "react";
import ContentLoader from "react-content-loader";

const Skeleton = ({ isLightMode, ...props }) => (
  <ContentLoader
    speed={2}
    width={50}
    height={50}
    viewBox="0 0 50 50"
    backgroundColor={isLightMode ? "#f5f5f5" : "#262626"}
    foregroundColor={isLightMode ? "#e2e2e2" : "#333333"}
    {...props}
  >
    <rect x="0" y="0" rx="4" ry="4" width="50" height="50" />
  </ContentLoader>
);

export default Skeleton;
