import React from "react";
import ButtonBack from "../../components/ButtonBack";
import toast, { Toaster } from "react-hot-toast";
import axios from "../../axios";
import fetchSettingOptions from "../../components/Setting";
import { useTranslation } from "react-i18next";
function Setting({
  settingPercentLow,
  settingPercentMiddle,
  settingPercentHigh,
  settingTotalLow,
  settingTotalMiddle,
}) {
  const { t } = useTranslation();
  const [percentLow, setPercentLow] = React.useState(settingPercentLow);
  const [percentMiddle, setPercentMiddle] =
    React.useState(settingPercentMiddle);
  const [percentHigh, setPercentHigh] = React.useState(settingPercentHigh);
  const [totalLow, setTotalLow] = React.useState(settingTotalLow);
  const [totalMiddle, setTotalMiddle] = React.useState(settingTotalMiddle);
  const [success, setSuccess] = React.useState(false);

  React.useEffect(() => {
    return () => {
      toast.remove();
    };
  }, []);

  async function handleFormSubmit(e) {
    e.preventDefault();

    // Проверить, были ли внесены изменения в форму
    if (
      percentLow === settingPercentLow &&
      percentMiddle === settingPercentMiddle &&
      percentHigh === settingPercentHigh &&
      totalLow === settingTotalLow &&
      totalMiddle === settingTotalMiddle
    ) {
      toast.error(t("error4"));
      return;
    }

    const updatedFormData = {
      percent_low: percentLow,
      percent_middle: percentMiddle,
      percent_high: percentHigh,
      total_low: totalLow,
      total_middle: totalMiddle,
    };

    try {
      await axios.put(`/admin/setting`, {
        updatedData: updatedFormData,
      });
      toast.promise(Promise.resolve(), {
        loading: t("info"),
        success: t("success4"),
      });
    } catch (error) {
      if (
        error.response &&
        error.response.data &&
        error.response.data.message
      ) {
        toast.promise(Promise.reject(error), {
          loading: t("info"),
          success: t("success4"),
          error: (error) => `${error.response.data.message}`,
        });
      }
    }
  }

  React.useEffect(() => {
    if (success) {
      const timer = setTimeout(() => {
        setSuccess(false);
      }, 5000);

      return () => clearTimeout(timer);
    }
  }, [success]);

  return (
    <main>
      <Toaster position="bottom-right" reverseOrder={false} />
      <div className="wrapper">
        <div className="content-users">
          <div className="head-selection">
            <div
              className="head-selection-line dragscroll"
              id="head-selection-line"
            >
              <div className="head-selection-item back">
                <ButtonBack />
              </div>
            </div>
          </div>
          <h1>{t("title10")}</h1>
          <div className="setting-page">
            <div className="setting-edit">
              <form onSubmit={handleFormSubmit}>
                <h3>{t("title11")}</h3>
                <div className="grid-input grid-three">
                  <label>
                    <p className="caption-3">{t("label7")}</p>
                    <input
                      value={percentLow}
                      className="user input"
                      placeholder={t("placeholder7")}
                      onChange={(e) => setPercentLow(e.target.value)}
                      required
                    />
                  </label>
                  <label>
                    <p className="caption-3">{t("label8")}</p>
                    <input
                      value={percentMiddle}
                      className="user input"
                      placeholder={t("placeholder8")}
                      onChange={(e) => setPercentMiddle(e.target.value)}
                      required
                    />
                  </label>
                  <label>
                    <p className="caption-3">{t("label9")}</p>
                    <input
                      value={percentHigh}
                      className="user input"
                      placeholder={t("placeholder9")}
                      onChange={(e) => setPercentHigh(e.target.value)}
                      required
                    />
                  </label>
                </div>
                <div className="grid-input">
                  <label>
                    <p className="caption-3">{t("label10")}</p>
                    <input
                      value={totalLow}
                      className="user input"
                      placeholder={t("placeholder16")}
                      onChange={(e) => setTotalLow(e.target.value)}
                      required
                    />
                  </label>
                  <label>
                    <p className="caption-3">{t("label11")}</p>
                    <input
                      value={totalMiddle}
                      className="user input"
                      placeholder={t("placeholder17")}
                      onChange={(e) => setTotalMiddle(e.target.value)}
                      required
                    />
                  </label>
                </div>
                <div className="button-block">
                  <button type="submit" className="button">
                    {t("btn12")}
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </main>
  );
}
export default fetchSettingOptions(Setting);
