import React from "react";
import moment from "moment";
import "moment/locale/ru";
import { useTranslation } from "react-i18next";

function SwitchLang() {
  const { i18n } = useTranslation();
  const [selectedLanguage, setSelectedLanguage] = React.useState(
    localStorage.getItem("selectedLanguage") || "ru"
  );

  React.useEffect(() => {
    i18n.changeLanguage(selectedLanguage);
    moment.locale(selectedLanguage);
    localStorage.setItem("selectedLanguage", selectedLanguage);
  }, [selectedLanguage, i18n]);

  const changeLanguage = (language) => {
    setSelectedLanguage(language);
  };

  return (
    <div className="language-switcher">
      <button
        onClick={() => changeLanguage("en")}
        className={
          selectedLanguage === "en" ? "button hidden" : "button black lang"
        }
      >
        <i className="icon-ru"></i>
        <span className="caption-3">Русский</span>
      </button>
      <button
        onClick={() => changeLanguage("ru")}
        className={
          selectedLanguage === "ru" ? "button hidden" : "button black lang"
        }
      >
        <i className="icon-en"></i>
        <span className="caption-3">English</span>
      </button>
    </div>
  );
}

export default SwitchLang;
