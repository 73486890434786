import React from "react";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";

function PageError() {
  const { t } = useTranslation();
  return (
    <div className="main">
      <div className="container">
        <main>
          <div className="not-found">
            <div className="not-found-block">
              <h1>{t("title4")}</h1>
              <p className="caption-2">{t("text")}</p>
              <Link to="/" className="button soc">
                {t("btn13")}
              </Link>
            </div>
          </div>
        </main>
      </div>
    </div>
  );
}

export default PageError;
