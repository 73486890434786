import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";

export const ecwidInstance = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
  headers: {
    "Content-Type": "application/json",
    "Access-Control-Allow-Origin": "*",
    "Access-Control-Allow-Methods": "GET,POST,PUT,DELETE,PATCH",
    "Access-Control-Allow-Headers":
      "x-access-token, x-requested-with, origin, Content-Type, Accept",
  },
});

export const getCategories = createAsyncThunk("collection", async () => {
  const { data } = await ecwidInstance.get(
    `${process.env.REACT_APP_ECWID_URL}${process.env.REACT_APP_ECWID_SHOP_ID}/categories`,
    {
      params: {
        token: process.env.REACT_APP_ECWID_PUBLIC_TOKEN,
        lang: "ru",
        parent: process.env.REACT_APP_ECWID_COLLECTION_ID,
        hidden_categories: false,
      },
    }
  );
  return data;
});

const initialState = {
  state: [],
  status: "loading",
};

const collSlice = createSlice({
  name: "collection",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getCategories.pending, (state) => {
        state.state = [];
        state.status = "loading";
      })
      .addCase(getCategories.fulfilled, (state, action) => {
        state.state = action.payload;
        state.status = "loaded";
      })
      .addCase(getCategories.rejected, (state) => {
        state.state = [];
        state.status = "error";
      });
  },
});

export const collReducer = collSlice.reducer;
