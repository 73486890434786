import React from "react";

const SwitchTheme = ({ isLightMode, toggleTheme }) => {
  React.useEffect(() => {
    const styleElement = document.createElement("style");
    styleElement.innerHTML = isLightMode && "";
    document.head.appendChild(styleElement);

    return () => {
      document.head.removeChild(styleElement);
    };
  }, [isLightMode]);

  return (
    <div className="theme-switch">
      <div className="switch" onClick={toggleTheme}>
        {isLightMode ? (
          <div className="button black themes">
            <i className="icon-moon"></i>
          </div>
        ) : (
          <div className="button black themes">
            <i className="icon-sun"></i>
          </div>
        )}
      </div>
      <label className="toggle-theme" htmlFor="checkbox">
        <input
          type="checkbox"
          id="checkbox"
          onChange={toggleTheme}
          checked={isLightMode}
        />
      </label>
    </div>
  );
};

export default SwitchTheme;
