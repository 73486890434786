import React from "react";
import axios from "../../axios";
import { useDispatch, useSelector } from "react-redux";
import Select from "react-select";
import toast, { Toaster } from "react-hot-toast";
import { useTranslation } from "react-i18next";

import ButtonBack from "../../components/ButtonBack";
import fetchAdminUserInfo from "../../components/Admin";
import { getCategories } from "../../redux/slises/collections";

function UserSetting({
  userId,
  userFirstName,
  userLastName,
  userEmail,
  userRoles,
  userAvatar,
  userToken,
  userPer,
  userRolesRus,
  userExtendedOrder,
}) {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const [avatarUrl, setAvatarUrl] = React.useState("");
  const [avatarName, setAvatarName] = React.useState("");
  const [userName, setUserName] = React.useState(
    userFirstName + " " + userLastName
  );
  const [roles, setRoles] = React.useState(userRoles);
  const [firstName, setFirstName] = React.useState(userFirstName);
  const [lastName, setLastName] = React.useState(userLastName);
  const [token, setToken] = React.useState(userToken);
  const [artistPer, setArtistPer] = React.useState(userPer);
  const [extendedOrder, setExtendedOrder] = React.useState(userExtendedOrder);
  const [password, setPassword] = React.useState("");
  const [success, setSuccess] = React.useState(false);
  const [showPassword, setShowPassword] = React.useState(false);

  const inputFileRef = React.useRef(null);

  const roleTranslations = {
    Admin: t("role_admin"),
    Redactor: t("role_editor"),
    Artist: t("role_artist"),
  };

  React.useEffect(() => {
    if (success) {
      const timer = setTimeout(() => {
        setSuccess(false);
      }, 5000);

      return () => clearTimeout(timer);
    }
  }, [success]);

  React.useEffect(() => {
    dispatch(getCategories());

    return () => {
      toast.remove();
    };
  }, []);

  const collection = useSelector((state) => state.coll.state.items);
  if (!Array.isArray(collection)) return null;
  const coll = collection.map(({ name }) => ({ value: name, label: name }));
  const role = [
    { value: "Admin", label: t("role_admin") },
    { value: "Redactor", label: t("role_editor") },
    { value: "Artist", label: t("role_artist") },
  ];

  const handleChangeFile = async (event) => {
    try {
      const formData = new FormData();
      const file = event.target.files[0];
      formData.append("image", file);
      const { data } = await axios.post("/upload", formData);
      setAvatarUrl(data.url);
      setAvatarName(data.name);
      toast.promise(Promise.resolve(), {
        loading: t("info"),
        success: t("success"),
        error: t("error"),
      });
    } catch (e) {
      console.error(e);
      toast.promise(Promise.reject(e), {
        loading: t("info"),
        success: t("success"),
        error: t("error"),
      });
    }
  };

  const onClickRemoveImage = async (event) => {
    setAvatarUrl("");
    try {
      toast.promise(Promise.resolve(), {
        loading: t("info2"),
        success: t("success2"),
      });
      await axios.delete("/upload", { data: { avatarName } });
    } catch (e) {
      console.error(e);
      toast.promise(Promise.reject(e), {
        loading: t("info2"),
        error: t("error"),
      });
    }
  };

  const onClickDeleteToast = () => {
    const a = toast((a) => (
      <span>
        <div className="toast-title-delete">{t("info3")}</div>
        <div className="toast-delete-btn">
          <button
            onClick={async () => {
              await handleAccountDelete();
              toast.remove(a.id);
            }}
          >
            {t("btn20")}
          </button>
          <button onClick={() => toast.dismiss(a.id)}>{t("btn21")}</button>
        </div>
      </span>
    ));
  };

  async function handleAccountDelete() {
    try {
      await axios.delete(`/user/${userId}/delete`);
      window.history.back();
    } catch (error) {
      console.error(error);
      toast.error(t("error8"));
    }
  }

  async function handleFormSubmit(e) {
    e.preventDefault();

    // Проверить, были ли внесены изменения в форму
    if (
      avatarUrl === "" &&
      password === "" &&
      extendedOrder === userExtendedOrder &&
      roles === userRoles &&
      firstName === userFirstName &&
      lastName === userLastName &&
      token === userToken &&
      artistPer === userPer
    ) {
      toast.error(t("error4"));
      return;
    }

    const updatedFormData = {
      avatarURL: avatarUrl,
      password: password,
      extendedOrder: extendedOrder,
      roles: roles,
      firstName: firstName,
      lastName: lastName,
      artistToken: token,
      artistPer: artistPer,
    };

    try {
      await axios.put(`/admin/user/${userId}/edit`, {
        userId,
        updatedData: updatedFormData,
      });
      console.log(userId);
      setUserName(firstName + " " + lastName);
      toast.success(t("success4"));
    } catch (error) {
      if (
        error.response &&
        error.response.data &&
        error.response.data.message
      ) {
        toast.error(error.response.data.message);
      } else {
        toast.error(t("error5"));
      }
    }
  }

  const handleTokenChange = (selectedOption) => {
    setToken(selectedOption.value);
  };

  const handleRolesChange = (selectedOption) => {
    setRoles(selectedOption.value);
  };

  const handleTogglePassword = () => {
    setShowPassword((prevShowPassword) => !prevShowPassword);
  };

  return (
    <main>
      <Toaster position="bottom-right" reverseOrder={false} />
      <div className="wrapper">
        <div className="content-users">
          <div className="head-selection">
            <div
              className="head-selection-line dragscroll"
              id="head-selection-line"
            >
              <div className="head-selection-item back">
                <ButtonBack />
              </div>
            </div>
          </div>
          <h1>{t("title13")}</h1>
          <div className="users">
            <div className="user-edit">
              <div className="user-info">
                <div className="user-avatar">
                  <div className="btn-avatar">
                    {avatarUrl ? (
                      <button onClick={onClickRemoveImage} className="soc">
                        <i className="icon-rem-avatar"></i>
                      </button>
                    ) : (
                      <button
                        onClick={() => inputFileRef.current.click()}
                        className="soc"
                      >
                        <i className="icon-add-avatar"></i>
                      </button>
                    )}
                  </div>
                  {avatarUrl ? (
                    <img
                      height="80"
                      width="80"
                      src={avatarUrl}
                      alt={userName}
                    />
                  ) : (
                    <img
                      src={userAvatar}
                      height="80"
                      width="80"
                      alt={userName}
                    />
                  )}
                </div>
                <div className="user-name">
                  <h2>{userName}</h2>
                  <p className="caption-2">{roleTranslations[roles]}</p>
                </div>
              </div>
              <form onSubmit={handleFormSubmit} autoComplete="off">
                <h3>Настройки мерчендайза</h3>
                <div className="grid-input">
                  <label>
                    <p className="caption-3">{t("label15")}</p>
                    <Select
                      options={coll}
                      value={coll.find((item) => item.value === token)}
                      onChange={handleTokenChange}
                      isSearchable={false}
                      className="select"
                      classNamePrefix="select"
                    />
                  </label>
                  <label>
                    <p className="caption-3">{t("label14")}</p>
                    <input
                      value={artistPer}
                      className="user input"
                      placeholder={t("placeholder7")}
                      onChange={(e) => setArtistPer(e.target.value)}
                    />
                  </label>
                </div>
                <div className="grid-input">
                  <label className="checkbox-wrapper">
                    <input
                      type="checkbox"
                      name="extendedOrder"
                      className="user checkbox"
                      checked={extendedOrder}
                      onChange={(e) => setExtendedOrder(e.target.checked)}
                    />
                    <div className="checkbox-slider">
                      <div className="checkbox-knob"></div>
                    </div>
                    <span className="caption-2">{t("label13")}</span>
                  </label>
                </div>
                <h3>Настройка профиля</h3>
                <div className="grid-input">
                  <input
                    ref={inputFileRef}
                    type="file"
                    onChange={handleChangeFile}
                    hidden
                  />
                  <label>
                    <p className="caption-3">{t("label")}</p>
                    <input
                      className="user input"
                      placeholder={t("placeholder")}
                      value={userEmail}
                      disabled
                    />
                  </label>
                  <label>
                    <p className="caption-3">{t("label12")}</p>
                    <Select
                      options={role}
                      value={role.find((group) => group.value === roles)}
                      onChange={handleRolesChange}
                      isSearchable={false}
                      className="select"
                      classNamePrefix="select"
                      onFocus={(event) => {
                        event.target.setAttribute("autocomplete", "off");
                        event.target.setAttribute("autocorrect", "off");
                        event.target.setAttribute("autocapitalize", "none");
                      }}
                    />
                  </label>
                  <label>
                    <p className="caption-3">{t("label3")}</p>
                    <input
                      value={firstName}
                      className="user input"
                      placeholder={t("placeholder3")}
                      onChange={(e) => setFirstName(e.target.value)}
                      required
                    />
                  </label>
                  <label>
                    <p className="caption-3">{t("label4")}</p>
                    <input
                      value={lastName}
                      className="user input"
                      placeholder={t("placeholder4")}
                      onChange={(e) => setLastName(e.target.value)}
                      required
                    />
                  </label>
                  <label>
                    <p className="caption-3">{t("label6")}</p>
                    <div className="grid-input-pass">
                      <input
                        value={password}
                        type={showPassword ? "text" : "password"}
                        autoComplete="new-password"
                        className="user input"
                        placeholder={t("placeholder6")}
                        onChange={(e) => setPassword(e.target.value)}
                      />
                      <span
                        onClick={handleTogglePassword}
                        className="password-toggle"
                      >
                        {showPassword ? (
                          <i className="icon-eye-close"></i>
                        ) : (
                          <i className="icon-eye"></i>
                        )}
                      </span>
                    </div>
                  </label>
                </div>
                <div className="button-block">
                  <button type="submit" className="button">
                    {t("btn12")}
                  </button>
                  <div
                    className="caption-2 delete-user-btn"
                    onClick={onClickDeleteToast}
                  >
                    <i className="icon-rem-avatar"></i>
                    {t("btn22")}
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </main>
  );
}

export default fetchAdminUserInfo(UserSetting);
