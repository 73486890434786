import { useTranslation } from "react-i18next";
import SwitchLang from "../Language";

function Footer() {
  const date = new Date();
  const currentYear = date.getFullYear();
  const { t } = useTranslation();

  return (
    <footer>
      <div className="container">
        <div className="left-block">
          <div className="copyright">
            <p className="caption-3">VSRAP Community © 2012 – {currentYear}</p>
          </div>
        </div>
        <div className="right-block">
          <div className="author">
            <p className="caption-3">
              {t("developer")}{" "}
              <a
                href="https://teletype.link/lekskokorin"
                rel="noopener noreferrer"
                target="_blank"
              >
                Plusite.ru
              </a>
            </p>
          </div>
          <div className="separator"></div>
          <SwitchLang />
        </div>
      </div>
    </footer>
  );
}

export default Footer;
