import React from "react";
import fetchUserInfo from "../../components/User";
import axios from "../../axios";
import ButtonBack from "../../components/ButtonBack";
import toast, { Toaster } from "react-hot-toast";
import { useTranslation } from "react-i18next";

function Setting({
  userId,
  userFirstName,
  userLastName,
  userEmail,
  userRoles,
  userToken,
  userAvatar,
  userRolesRus,
  userExtendedOrder,
}) {
  const { t } = useTranslation();
  const [showPassword, setShowPassword] = React.useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = React.useState(false);
  const [avatarUrl, setAvatarUrl] = React.useState("");
  const [avatarName, setAvatarName] = React.useState("");
  const [userName, setUserName] = React.useState(
    userFirstName + " " + userLastName
  );
  const [firstName, setFirstName] = React.useState(userFirstName);
  const [lastName, setLastName] = React.useState(userLastName);
  const [oldPassword, setOldPassword] = React.useState("");
  const [password, setPassword] = React.useState("");
  // const [extendedOrder, setExtendedOrder] = React.useState(userExtendedOrder);
  const [success, setSuccess] = React.useState(false);
  const inputFileRef = React.useRef(null);

  React.useEffect(() => {
    return () => {
      toast.remove();
    };
  }, []);

  const handleChangeFile = async (event) => {
    try {
      const formData = new FormData();
      const file = event.target.files[0];
      formData.append("image", file);
      const { data } = await axios.post("/upload", formData);
      setAvatarUrl(data.url);
      setAvatarName(data.name);
      toast.promise(Promise.resolve(), {
        loading: t("info"),
        success: t("success"),
        error: t("error"),
      });
    } catch (e) {
      console.error(e);
      toast.promise(Promise.reject(e), {
        loading: t("info"),
        success: t("success"),
        error: t("error"),
      });
    }
  };

  const onClickRemoveImage = async (event) => {
    setAvatarUrl("");
    try {
      toast.promise(Promise.resolve(), {
        loading: t("info2"),
        success: t("success2"),
      });
      await axios.delete("/upload", { data: { avatarName } });
    } catch (e) {
      console.error(e);
      toast.promise(Promise.reject(e), {
        loading: t("info2"),
        error: t("error"),
      });
    }
  };

  async function handleFormSubmit(e) {
    e.preventDefault();

    // Проверить, были ли внесены изменения в форму
    if (
      avatarUrl === "" &&
      firstName === userFirstName &&
      lastName === userLastName &&
      // extendedOrder === userExtendedOrder &&
      password === ""
    ) {
      toast.error(t("error4"));
      return;
    }

    // Обновляем данные формы
    const updatedFormData = {
      firstName: firstName,
      avatarURL: avatarUrl,
      lastName: lastName,
      oldPassword: oldPassword,
      password: password,
      // extendedOrder: extendedOrder,
    };

    try {
      await axios.put(`/user/${userId}/edit`, {
        userId,
        updatedData: updatedFormData,
      });
      setUserName(firstName + " " + lastName);
      toast.success(t("success4"));
    } catch (error) {
      if (
        error.response &&
        error.response.data &&
        error.response.data.message
      ) {
        toast.error(error.response.data.message);
      } else {
        toast.error(t("error5"));
      }
    }
  }

  React.useEffect(() => {
    if (success) {
      const timer = setTimeout(() => {
        setSuccess(false);
      }, 5000);

      return () => clearTimeout(timer);
    }
  }, [success]);

  const handleTogglePassword = () => {
    setShowPassword((prevShowPassword) => !prevShowPassword);
  };

  const handleToggleConfirmPassword = () => {
    setShowConfirmPassword((prevShowPassword) => !prevShowPassword);
  };

  return (
    <main>
      <Toaster position="bottom-right" reverseOrder={false} />
      <div className="wrapper">
        <div className="content-users">
          <div className="head-selection">
            <div
              className="head-selection-line dragscroll"
              id="head-selection-line"
            >
              <div className="head-selection-item back">
                <ButtonBack />
              </div>
            </div>
          </div>
          <h1>{t("title3")}</h1>
          <div className="users">
            <div className="user-edit">
              <div className="user-info">
                <div className="user-avatar">
                  <div className="btn-avatar">
                    {avatarUrl ? (
                      <button onClick={onClickRemoveImage} className="soc">
                        <i className="icon-rem-avatar"></i>
                      </button>
                    ) : (
                      <button
                        onClick={() => inputFileRef.current.click()}
                        className="soc"
                      >
                        <i className="icon-add-avatar"></i>
                      </button>
                    )}
                  </div>
                  {avatarUrl ? (
                    <img
                      height="80"
                      width="80"
                      src={avatarUrl}
                      alt={userName}
                    />
                  ) : (
                    <img
                      src={userAvatar}
                      height="80"
                      width="80"
                      alt={userName}
                    />
                  )}
                </div>
                <div className="user-name">
                  <h2>{userName}</h2>
                  <p className="caption-2">{userRolesRus}</p>
                </div>
              </div>
              <form onSubmit={handleFormSubmit}>
                <div className="grid-input">
                  <input
                    ref={inputFileRef}
                    type="file"
                    onChange={handleChangeFile}
                    hidden
                  />
                  <label>
                    <p className="caption-3">{t("label")}</p>
                    <input
                      className="user input"
                      placeholder={t("placeholder")}
                      value={userEmail}
                      disabled
                    />
                  </label>
                  <label>
                    <p className="caption-3">{t("label2")}</p>
                    <input
                      className="user input"
                      placeholder={t("placeholder2")}
                      value={userToken}
                      disabled
                    />
                  </label>
                  <label>
                    <p className="caption-3">{t("label3")}</p>
                    <input
                      value={firstName}
                      className="user input"
                      placeholder={t("placeholder3")}
                      onChange={(e) => setFirstName(e.target.value)}
                      required
                    />
                  </label>
                  <label>
                    <p className="caption-3">{t("label4")}</p>
                    <input
                      value={lastName}
                      className="user input"
                      placeholder={t("placeholder4")}
                      onChange={(e) => setLastName(e.target.value)}
                      required
                    />
                  </label>
                  <label>
                    <p className="caption-3">{t("label5")}</p>
                    <div className="grid-input-pass">
                      <input
                        value={oldPassword}
                        type={showPassword ? "text" : "password"}
                        autoComplete="off"
                        className="user input"
                        placeholder={t("placeholder5")}
                        onChange={(e) => setOldPassword(e.target.value)}
                      />
                      <span
                        onClick={handleTogglePassword}
                        className="password-toggle"
                      >
                        {showPassword ? (
                          <i className="icon-eye-close"></i>
                        ) : (
                          <i className="icon-eye"></i>
                        )}
                      </span>
                    </div>
                  </label>
                  <label>
                    <p className="caption-3">{t("label6")}</p>
                    <div className="grid-input-pass">
                      <input
                        value={password}
                        type={showConfirmPassword ? "text" : "password"}
                        autoComplete="new-password"
                        className="user input"
                        placeholder={t("placeholder6")}
                        onChange={(e) => setPassword(e.target.value)}
                      />
                      <span
                        onClick={handleToggleConfirmPassword}
                        className="password-toggle"
                      >
                        {showConfirmPassword ? (
                          <i className="icon-eye-close"></i>
                        ) : (
                          <i className="icon-eye"></i>
                        )}
                      </span>
                    </div>
                  </label>
                  {/*<label className="checkbox-wrapper">*/}
                  {/*  <input*/}
                  {/*    type="checkbox"*/}
                  {/*    name="extendedOrder"*/}
                  {/*    className="user checkbox"*/}
                  {/*    checked={extendedOrder}*/}
                  {/*    onChange={(e) => setExtendedOrder(e.target.checked)}*/}
                  {/*  />*/}
                  {/*  <div className="checkbox-slider">*/}
                  {/*    <div className="checkbox-knob"></div>*/}
                  {/*  </div>*/}
                  {/*  <span className="caption-2">*/}
                  {/*    Отображать блок выручки в отчетах о продажах*/}
                  {/*  </span>*/}
                  {/*</label>*/}
                </div>
                <div className="button-block">
                  <button type="submit" className="button">
                    {t("btn12")}
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </main>
  );
}

export default fetchUserInfo(Setting);
