import React from "react";
import moment from "moment";
import { useTranslation } from "react-i18next";
import PeriodSelectors from "../components/Sales/Period";
import OrderList from "../components/Sales/Order";
import SellerBlock from "../components/Sales/Seller";

function Home(props) {
  const { t } = useTranslation();
  const currentYear = moment().year();
  const currentMonth = moment().format("MM");
  const currentMonthName = moment().format("MMMM");
  const сurrentDate = currentYear + "-" + currentMonth;

  const [sumTotal, setSumTotal] = React.useState(0);
  const [sumGain, setSumGain] = React.useState(0);
  const [sumCount, setSumCount] = React.useState(0);
  const [selectedPeriod, setSelectedPeriod] = React.useState(сurrentDate);
  const [selectedArtistToken, setSelectedArtistToken] = React.useState();
  const [selectedMonth, setSelectedMonth] = React.useState(currentMonthName);
  const [selectedYear, setSelectedYear] = React.useState(currentYear);

  const amountTotal = (sumTotal) => {
    setSumTotal(sumTotal);
  };

  const amountGain = (sumGain) => {
    setSumGain(sumGain);
  };

  const amountCount = (sumCount) => {
    setSumCount(sumCount);
  };

  const selectMonth = (selectedMonth) => {
    setSelectedMonth(selectedMonth);
  };

  const selectYear = (selectedYear) => {
    setSelectedYear(selectedYear);
  };

  const selectData = (selectedPeriod) => {
    setSelectedPeriod(selectedPeriod);
  };

  const selectArtistToken = (selectedArtistToken) => {
    setSelectedArtistToken(selectedArtistToken);
  };

  return (
    <main>
      <div className="content">
        <div className="content-orders">
          <PeriodSelectors
            selectToData={selectData}
            selectToYear={selectYear}
            selectToMonth={selectMonth}
          />
          <h1>{t("title2")}</h1>
          <div className="orders">
            <OrderList
              selectedPeriod={selectedPeriod}
              selectedArtistToken={selectedArtistToken}
              amountTotal={amountTotal}
              amountGain={amountGain}
              amountCount={amountCount}
            />
          </div>
        </div>
        <div className="right-block">
          <SellerBlock
            sumCount={sumCount}
            sumTotal={sumTotal}
            sumGain={sumGain}
            selectedMonth={selectedMonth}
            selectedYear={selectedYear}
            selectToArtistToken={selectArtistToken}
          />
        </div>
      </div>
    </main>
  );
}

export default Home;
