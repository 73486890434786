import axios from "axios";

export const ecwidInstance = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
  headers: {
    "Content-Type": "application/json",
    "Access-Control-Allow-Origin": "*",
    "Access-Control-Allow-Methods": "GET,POST,PUT,DELETE,PATCH",
    "Access-Control-Allow-Headers":
      "x-access-token, x-requested-with, origin, Content-Type, Accept",
  },
});

export const shopAPI = {
  getProduct: async (productId) => {
    return await ecwidInstance.get(
      `${process.env.REACT_APP_ECWID_URL}${process.env.REACT_APP_ECWID_SHOP_ID}/products/${productId}`,
      {
        params: {
          token: process.env.REACT_APP_ECWID_PUBLIC_TOKEN,
          lang: "ru",
        },
      }
    );
  },
  getCategories: async () => {
    return await ecwidInstance.get(
      `${process.env.REACT_APP_ECWID_URL}${process.env.REACT_APP_ECWID_SHOP_ID}/categories`,
      {
        params: {
          token: process.env.REACT_APP_ECWID_PUBLIC_TOKEN,
          lang: "ru",
          parent: process.env.REACT_APP_ECWID_COLLECTION_ID,
          hidden_categories: false,
        },
      }
    );
  },
};
