import React from "react";
import { Link } from "react-router-dom";

function UserItem({ userId, userName, userAvatar, userRoles }) {
  return (
    <div className="users-block-item" key={userName}>
      <div className="user-info">
        <div className="user-avatar">
          <img src={userAvatar} height="500" width="500" alt={userName} />
        </div>
        <div className="user-name">
          <Link to={`/admin/users/${userId}`}>
            <h3>{userName}</h3>
          </Link>
          <p className="caption-4">{userRoles}</p>
        </div>
      </div>
    </div>
  );
}

export default UserItem;
