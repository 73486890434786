import React from "react";
import { useFetchData } from "../../../utils/Api1C";
import OrderBlock from "./Item";
import Skeleton from "./Skeleton";
import { ThemeContext } from "../../Theme/ThemeContext";
import fetchUserInfo from "../../User";
import fetchSettingOptions from "../../Setting";
import { useTranslation } from "react-i18next";

const OrderList = ({
  settingPercentLow,
  settingPercentMiddle,
  settingPercentHigh,
  settingTotalLow,
  settingTotalMiddle,
  selectedPeriod,
  selectedArtistToken,
  amountTotal,
  amountGain,
  amountCount,
  userToken,
}) => {
  const { t } = useTranslation();
  const [isLoading, setIsLoading] = React.useState(false);

  const updatedArtistToken = selectedArtistToken
    ? selectedArtistToken
    : userToken;

  const {
    data: saleData,
    isLoading: isFetchLoading,
    isError,
    refetch,
  } = useFetchData(updatedArtistToken);

  const fetchSaleData = async () => {
    setIsLoading(true);
    await refetch();
    setIsLoading(false);
  };

  React.useEffect(() => {
    fetchSaleData();
  }, [updatedArtistToken]);

  const { isLightMode } = React.useContext(ThemeContext);

  const skeleton = [...new Array(6)].map((_, index) => (
    <Skeleton isLightMode={isLightMode} key={index} />
  ));

  const [visibleOrdersValue, setVisibleOrdersValue] = React.useState(15);

  React.useEffect(() => {
    const handleScroll = () => {
      if (
        window.innerHeight + window.pageYOffset >=
        document.documentElement.scrollHeight
      ) {
        setVisibleOrdersValue((prevVisibleOrders) => prevVisibleOrders + 15);
      }
    };
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [selectedPeriod]);

  React.useEffect(() => {
    setVisibleOrdersValue(15);
  }, [selectedPeriod]);

  const visibleOrders = visibleOrdersValue;

  if (isLoading || isFetchLoading) {
    return <div className="order-list">{skeleton}</div>;
  }

  if (isError) {
    return <div>{t("error11")}</div>;
  }

  function generateUniqueID() {
    return Math.random().toString(36).substr(2, 5);
  }

  const convertSaleData = saleData.map((item) => ({
    productID: item.ID,
    orderID: item.IDЗаказ,
    date: item.Дата,
    name: item.Номенклатура,
    variant: item.Характеристика,
    count: item.Количество,
    price: item.Цена,
    total: item.Сумма,
  }));

  const orders = convertSaleData.reduce((acc, item) => {
    let orderId;
    if (item.count >= 0) {
      orderId = item.orderID || `Rozn-${generateUniqueID()}`;
    } else {
      orderId = `Vozv-${item.orderID}`;
    }
    if (!acc[orderId]) {
      acc[orderId] = {
        orderID: orderId,
        date: item.date.split("T")[0],
        count: 0,
        total: 0,
        products: [],
      };
    }
    acc[orderId].products.push({
      name: item.name,
      variant: item.variant,
      productId: item.productID,
      count: item.count,
      price: item.price,
      total: item.total,
    });
    acc[orderId].count += item.count;
    acc[orderId].total += item.total;
    return acc;
  }, {});

  const saleOrders = Object.values(orders)
    .filter((order) => {
      return order.date.startsWith(selectedPeriod);
    })
    .sort((a, b) => {
      const dateA = new Date(a.date);
      const dateB = new Date(b.date);
      return dateA - dateB;
    })
    .reverse();

  const sumTotalCount = () => {
    const [sumCount, sumTotal] = saleOrders.reduce(
      (acc, order) => {
        acc[0] += order.count;
        acc[1] += order.total;
        return acc;
      },
      [0, 0]
    );

    amountCount(sumCount);

    const sumGain = Math.round(sumTotal)
      .toString()
      .replace(/\B(?=(\d{3})+(?!\d))/g, " ");
    let percentage;

    switch (true) {
      case sumTotal < settingTotalLow:
        percentage = settingPercentLow;
        break;
      case sumTotal < settingTotalMiddle:
        percentage = settingPercentMiddle;
        break;
      default:
        percentage = settingPercentHigh;
        break;
    }

    const sumTotalPercentage = Math.round((sumTotal / 100) * percentage)
      .toString()
      .replace(/\B(?=(\d{3})+(?!\d))/g, " ");

    amountTotal(sumTotalPercentage);
    amountGain(sumGain);
  };

  sumTotalCount();

  return (
    <>
      <div className={`order-list ${saleOrders.length === 0 ? "noblock" : ""}`}>
        {saleOrders.slice(0, visibleOrders).map((order) => (
          <OrderBlock
            key={order.orderID}
            orderID={order.orderID}
            total={order.total}
            date={order.date}
            count={order.count}
            products={order.products}
            productsId={order.products.productId}
          />
        ))}
      </div>
      {saleOrders.length === 0 && (
        <p className="caption-2 info">{t("error12")}</p>
      )}
    </>
  );
};

export default fetchSettingOptions(fetchUserInfo(OrderList));
