import React from "react";
import { Link, useNavigate } from "react-router-dom";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import toast, { Toaster } from "react-hot-toast";
import SocialLoginButtons from "../../components/SocialButton";
import FingerprintJS from "@fingerprintjs/fingerprintjs";
import { useTranslation } from "react-i18next";
import SwitchLang from "../../components/Language";
import {
  registration,
  clearErrorAfterTime,
  clearErrorOnNavigation,
  selectError,
} from "../../redux/slises/auth";

function SignUp(props) {
  const { t } = useTranslation();
  const [showPassword, setShowPassword] = React.useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = React.useState(false);
  const error = useSelector(selectError);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  React.useEffect(() => {
    return () => {
      toast.remove();
    };
  }, []);

  const {
    register,
    watch,
    handleSubmit,
    formState: { errors },
  } = useForm({
    defaultValues: {},
    mode: "onSubmit",
    reValidateMode: "onSubmit",
  });

  React.useEffect(() => {
    if (error) {
      dispatch(clearErrorAfterTime());
      toast.error(error);
    }
  }, [error, dispatch]);

  const handleNavigation = () => {
    dispatch(clearErrorOnNavigation());
  };

  const onSubmit = async (values) => {
    const deviceId = await getDeviceId();
    values.deviceId = deviceId;

    const data = await dispatch(registration(values));

    if (!data.payload) {
      return;
    }

    if ("accessToken" in data.payload) {
      window.localStorage.setItem("token", data.payload.accessToken);
      navigate("/");
    }
  };

  const getDeviceId = async () => {
    try {
      // Инициализация объекта FingerprintJS
      const fp = await FingerprintJS.load();

      // Определение уникального идентификатора устройства
      const result = await fp.get();
      const visitorId = result.visitorId;

      return visitorId;
    } catch (error) {
      console.error(t("error10"));
    }
  };

  const handleTogglePassword = () => {
    setShowPassword((prevShowPassword) => !prevShowPassword);
  };

  const handleToggleConfirmPassword = () => {
    setShowConfirmPassword((prevShowPassword) => !prevShowPassword);
  };

  return (
    <div className="sign">
      <section className="floating-block">
        <SwitchLang />
      </section>
      <Toaster position="bottom-right" reverseOrder={false} />
      <section className="auth-block">
        <div className="sign-block">
          <div className="sign-logo">
            <span className="caption-4">{t("title5")}</span>
            <Link to="/" className="">
              <i className="icon-logo"></i>
            </Link>
          </div>
          <div className="sign-form">
            <div className="sign-form-title">
              <h1>{t("title18")}</h1>
            </div>
            <form
              onSubmit={handleSubmit(onSubmit)}
              className="form__form-box form-box"
            >
              <div className="label-blind">
                <label>
                  <input
                    className={errors?.firstName && "error"}
                    type="text"
                    placeholder={t("placeholder13")}
                    {...register("firstName", {
                      required: t("error14"),
                      maxLength: {
                        value: 80,
                        message: t("error15"),
                      },
                    })}
                  />
                  <div className="hidden">
                    {errors?.firstName?.message &&
                      toast.error(errors?.firstName?.message, {
                        toastId: "firstName-error",
                      })}
                  </div>
                </label>
                <label>
                  <input
                    className={errors?.lastName && "error"}
                    type="text"
                    placeholder={t("placeholder14")}
                    {...register("lastName", {
                      required: t("error16"),
                      maxLength: {
                        value: 80,
                        message: t("error17"),
                      },
                    })}
                  />
                  <div className="hidden">
                    {errors?.lastName?.message &&
                      toast.error(errors?.lastName?.message, {
                        toastId: "lastName-error",
                      })}
                  </div>
                </label>
              </div>
              <label>
                <input
                  className={errors?.email && "error"}
                  type="email"
                  placeholder={t("placeholder11")}
                  {...register("email", {
                    required: t("error18"),
                    pattern: {
                      value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                      message: t("error19"),
                    },
                  })}
                />
                <div className="hidden">
                  {errors?.email &&
                    toast.error(errors?.email?.message, {
                      toastId: "email-error",
                    })}
                </div>
              </label>
              <label>
                <input
                  className={errors?.password && "error"}
                  type={showPassword ? "text" : "password"}
                  autoComplete="on"
                  placeholder={t("placeholder12")}
                  {...register("password", {
                    required: t("error20"),
                    minLength: {
                      value: 6,
                      message: t("error21"),
                    },
                  })}
                />
                <span
                  onClick={handleTogglePassword}
                  className="password-toggle"
                >
                  {showPassword ? (
                    <i className="icon-eye-close"></i>
                  ) : (
                    <i className="icon-eye"></i>
                  )}
                </span>
                <div className="hidden">
                  {errors?.password?.message &&
                    toast.error(errors?.password?.message, {
                      toastId: "password-error",
                    })}
                </div>
              </label>
              <label>
                <input
                  className={errors?.confirmPassword && "error"}
                  type={showConfirmPassword ? "text" : "password"}
                  autoComplete="on"
                  placeholder={t("placeholder15")}
                  {...register("confirmPassword", {
                    required: t("error22"),
                    validate: (val) => {
                      if (watch("password") !== val) {
                        return t("error23");
                      }
                    },
                  })}
                />
                <span
                  onClick={handleToggleConfirmPassword}
                  className="password-toggle"
                >
                  {showConfirmPassword ? (
                    <i className="icon-eye-close"></i>
                  ) : (
                    <i className="icon-eye"></i>
                  )}
                </span>
                <div className="hidden">
                  {errors?.confirmPassword &&
                    toast.error(errors?.confirmPassword?.message, {
                      toastId: "confirmPassword-error",
                    })}
                </div>
              </label>
              {error && (
                <div className="info-form">
                  <p className="caption-2">{error}</p>
                </div>
              )}
              <button type="submit">{t("btn26")}</button>
            </form>
            <div className="sign-form-link">
              <Link
                to="/account/login"
                onClick={handleNavigation}
                className="button black"
              >
                {t("btn23")}
              </Link>
            </div>
          </div>
          <SocialLoginButtons />
        </div>
      </section>
      <section className="bg-block">
        <div className="bg-sign"></div>
      </section>
    </div>
  );
}

export default SignUp;
