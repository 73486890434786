import { configureStore } from "@reduxjs/toolkit";
import { usersReducer } from "./slises/users";
import { authReducer } from "./slises/auth";
import { collReducer } from "./slises/collections";

const store = configureStore({
  reducer: {
    users: usersReducer,
    auth: authReducer,
    coll: collReducer,
  },
});

export default store;
