import React from "react";
import moment from "moment";
import Tippy from "@tippyjs/react";
import "tippy.js/dist/tippy.css";
import { useTranslation } from "react-i18next";

import { shopAPI } from "../../../../utils/ApiEcwid";
import { ThemeContext } from "../../../Theme/ThemeContext";
import Skeleton from "./Skeleton";

function OrderBlock({ orderID, total, date, count, products, productsId }) {
  const { t } = useTranslation();
  const { isLightMode } = React.useContext(ThemeContext);
  const [thumbnailUrls, setThumbnailUrls] = React.useState([]);
  const [productLink, setProductLink] = React.useState([]);

  React.useEffect(() => {
    const fetchProducts = async () => {
      try {
        const img = [];
        const url = [];
        for (const product of products) {
          const response = await shopAPI.getProduct(product.productId);
          const thumbnail = response.data.thumbnailUrl;
          const link = response.data.url;
          img.push(link);
          url.push(thumbnail);

          // Кэширование картинки на стороне браузера сроком на 30 дней
          response.headers["Cache-Control"] = "public, max-age=2592000";
        }
        setProductLink(img);
        setThumbnailUrls(url);
      } catch (error) {}
    };

    fetchProducts();
  }, [products]);

  return (
    <div className="order-block">
      <div className="order-block-info">
        <div className="order-block-title">
          <div className="order-block-sum">
            <h2>
              {total > 0 ? <h2>{total + " ₽"}</h2> : <h2>{total + " ₽"}</h2>}
            </h2>
          </div>
          <div className="order-block-other">
            <span className="order-block-date caption-4">
              {moment(date).format("DD.MM.YYYY")}
            </span>
            {orderID.startsWith("Vozv") ? (
              <>
                <span className="order-block-numb caption-4">
                  {t("order2")}
                </span>
                <span className="order-block-numb caption-4">
                  №{orderID.substring(5)}
                </span>
              </>
            ) : orderID.startsWith("Rozn") ? (
              <span className="order-block-numb caption-4">{t("order3")}</span>
            ) : (
              <span className="order-block-numb caption-4">№{orderID}</span>
            )}
            {count > 0 ? (
              <span className="order-block-count caption-4">
                {count} {t("order4")}
              </span>
            ) : (
              <span className="order-block-count caption-4">
                {Math.abs(count)} {t("order4")}
              </span>
            )}
          </div>
        </div>
        <div className="order-block-list">
          {products.map((product, index) => (
            <p className="caption-3" key={index}>
              {product.name}
            </p>
          ))}
        </div>
      </div>
      <div className="order-item-list">
        {products.map((product, index) => (
          <div className="order-item" key={index}>
            <div className="order-item-img">
              <Tippy content={`${product.name}`}>
                <a
                  href={productLink[index]}
                  id="singleElement"
                  target={"_blank"}
                  rel="noreferrer"
                >
                  {thumbnailUrls.length > 0 ? (
                    <img
                      src={thumbnailUrls[index]}
                      height="50"
                      width="50"
                      alt={product.name}
                    />
                  ) : (
                    <Skeleton isLightMode={isLightMode} />
                  )}
                </a>
              </Tippy>
            </div>
            <div className="order-item-size">
              <span className="caption-4">{product.variant}</span>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
}

export default OrderBlock;
