import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import toast, { Toaster } from "react-hot-toast";
import fetchUserInfo from "../../components/User";
import SocialLoginButtons from "../../components/SocialButton";
import { useTranslation } from "react-i18next";
import SwitchLang from "../../components/Language";

import {
  logout,
  activate,
  clearErrorAfterTime,
  clearErrorOnNavigation,
  selectError,
} from "../../redux/slises/auth";

function Activate({ userEmail }) {
  const { t } = useTranslation();
  const error = useSelector(selectError);
  const email = useSelector((state) => state.auth.data.user.email);
  const dispatch = useDispatch();

  React.useEffect(() => {
    if (error) {
      dispatch(clearErrorAfterTime());
      if (error === t("error24")) {
        toast.error(t("error25"));
      } else if (error === t("success5")) {
        toast.success(t("success6"));
      } else {
        toast.error(t("success26"));
      }
    }
  }, [error, dispatch]);

  const handleNavigation = () => {
    dispatch(clearErrorOnNavigation());
  };

  const onClickLogout = () => {
    dispatch(logout());
  };

  const handleButtonClick = async () => {
    dispatch(activate(email));
  };

  return (
    <div className="sign">
      <section className="floating-block">
        <SwitchLang />
      </section>
      <Toaster position="bottom-right" reverseOrder={false} />
      <section className="auth-block">
        <div className="sign-block">
          <div className="sign-logo">
            <span className="caption-4">{t("title5")}</span>
            <Link to="/" className="">
              <i className="icon-logo"></i>
            </Link>
          </div>
          <div className="sign-form">
            <div className="sign-form-title">
              <h1>{t("title20")}</h1>
            </div>
            <div className="info-form">
              <p className="caption-2">{t("text5")}</p>
              <p className="caption-2">
                {t("text6")} {userEmail}
              </p>
            </div>
            {error && (
              <div className="info-form err">
                <p className="caption-2">{error}</p>
              </div>
            )}
            <div className="sign-form-link">
              <button onClick={handleButtonClick}>{t("btn28")}</button>
            </div>
            <div className="sign-form-link">
              <Link
                to="#"
                className="button black"
                onClick={() => {
                  handleNavigation();
                  onClickLogout();
                }}
              >
                {t("btn9")}
              </Link>
            </div>
          </div>
          <SocialLoginButtons />
        </div>
      </section>
      <section className="bg-block">
        <div className="bg-sign"></div>
      </section>
    </div>
  );
}

export default fetchUserInfo(Activate);
