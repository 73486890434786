import React from "react";
import axios from "../../axios";
import { useQuery } from "react-query";
import { useTranslation } from "react-i18next";

async function fetchUser() {
  const response = await axios.get(`/user`);
  return response.data;
}

function fetchUserInfo(WrappedComponent) {
  return function UserInfoWrapper(props) {
    const { t } = useTranslation();
    const { data, isLoading, isError } = useQuery("user", fetchUser, {
      keepPreviousData: true,
      refetchOnWindowFocus: false,
    });

    const roleTranslations = {
      Admin: t("role_admin"),
      Redactor: t("role_editor"),
      Artist: t("role_artist"),
    };

    return (
      !isLoading &&
      !isError &&
      data && (
        <WrappedComponent
          userId={data._id}
          userName={data.firstName + " " + data.lastName}
          userLastName={data.lastName}
          userFirstName={data.firstName}
          userEmail={data.email}
          userRoles={data.roles.join(", ")}
          userRolesRus={roleTranslations[data.roles.join(", ")]}
          userAvatar={data.avatarURL}
          userToken={data.artistToken}
          userPer={data.artistPer}
          userExtendedOrder={data.isExtendedOrder}
          {...props}
        />
      )
    );
  };
}

export default fetchUserInfo;
