import React from "react";
import fetchUserInfo from "../../User";
import Tippy from "@tippyjs/react";
import "tippy.js/dist/tippy.css";
import { shopAPI } from "../../../utils/ApiEcwid";
import { useTranslation } from "react-i18next";

function SellerBlock({
  sumTotal,
  sumCount,
  sumGain,
  selectedMonth,
  selectedYear,
  userName,
  userAvatar,
  userFirstName,
  userLastName,
  userToken,
  userRoles,
  userExtendedOrder,
  selectToArtistToken,
}) {
  const { t } = useTranslation();
  const blockRef = React.useRef(null);
  const [isSticky, setIsSticky] = React.useState(false);
  const [blockWidth, setBlockWidth] = React.useState(null);
  const [isProfit, setIsProfit] = React.useState(true);
  const [categories, setCategories] = React.useState([]);
  const [activeArtist, setActiveArtist] = React.useState("");
  const classes = isSticky ? "seller-block sticky-seller" : "seller-block";

  const handleResize = React.useCallback(() => {
    setBlockWidth(blockRef.current.offsetWidth);
  }, []);

  const handleScroll = React.useCallback(() => {
    const scrollTop = window.pageYOffset || document.documentElement.scrollTop;
    setIsSticky(scrollTop > 106);
  }, []);

  const style = {
    width: isSticky ? blockWidth : "auto",
  };

  const handleProfitClick = () => {
    setIsProfit(!isProfit);
  };

  React.useEffect(() => {
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, [handleResize]);

  React.useEffect(() => {
    fetchCategories();
    setActiveArtist(userToken);
  }, []);

  React.useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [handleScroll]);

  React.useEffect(() => {
    setBlockWidth(blockRef.current.offsetWidth);
  }, [blockRef]);

  async function fetchCategories() {
    try {
      const response = await shopAPI.getCategories();
      const categories = response.data.items;
      const filteredCategories = categories.filter(
        (category) =>
          category.parentId == process.env.REACT_APP_ECWID_COLLECTION_ID
      );
      setCategories(filteredCategories);
    } catch (error) {
      console.error(error);
    }
  }

  const handleArtistClick = (event) => {
    const artistToken = event.target.getAttribute("data-artist");
    setActiveArtist(artistToken);
    selectToArtistToken(artistToken);
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  return (
    <>
      <div className={classes} ref={blockRef} style={style}>
        <div className="seller">
          <div className="seller-info">
            <div className="seller-avatar">
              {categories.map((category) => {
                if (category.name === activeArtist) {
                  return (
                    <img
                      key={category.id}
                      src={
                        category.thumbnailUrl
                          ? category.thumbnailUrl
                          : `${process.env.REACT_APP_API_URL}/uploads/no-avatar.png`
                      }
                      height="500"
                      width="500"
                      alt={category.name}
                    />
                  );
                }
              })}
            </div>
            <div className="seller-name">
              <p className="caption-4">{t("title14")}</p>
              <h2>
                {activeArtist || userToken
                  ? activeArtist || userToken
                  : userFirstName + " " + userLastName}
              </h2>
            </div>
          </div>
          <div className="seller-amount">
            <div className="seller-amount-item">
              <p className="caption-4">{selectedMonth}</p>
              <div className="seller-amount-total">
                <h2>{selectedYear}</h2>
              </div>
            </div>
            <div className="seller-amount-item">
              <p className="caption-4">{t("title15")}</p>
              <div className="seller-amount-total">
                <h2>{sumCount}</h2>
                <span className="caption-2">{t("order4")}</span>
              </div>
            </div>
            {userExtendedOrder ? (
              <>
                {isProfit ? (
                  <div
                    className="seller-amount-item total-item"
                    onClick={handleProfitClick}
                  >
                    <p className="caption-4">
                      {t("title17")}
                      <Tippy content={t("info4")}>
                        <i className="icon-info"></i>
                      </Tippy>
                    </p>
                    <div className="seller-amount-total">
                      <h2>{sumTotal}</h2>
                      <span className="caption-2">₽</span>
                    </div>
                  </div>
                ) : (
                  <div
                    className="seller-amount-item total-item"
                    onClick={handleProfitClick}
                  >
                    <p className="caption-4">
                      {t("title16")}
                      <Tippy content={t("info4")}>
                        <i className="icon-info"></i>
                      </Tippy>
                    </p>
                    <div className="seller-amount-total">
                      <h2>{sumGain}</h2>
                      <span className="caption-2">₽</span>
                    </div>
                  </div>
                )}
              </>
            ) : (
              <>
                <div className="seller-amount-item total-item">
                  <p className="caption-4">{t("title17")}</p>
                  <div className="seller-amount-total">
                    <h2>{sumTotal}</h2>
                    <span className="caption-2">₽</span>
                  </div>
                </div>
              </>
            )}
          </div>
        </div>
        {userRoles === "Admin" && (
          <>
            <div className="artist-list">
              <ul>
                {categories.map((category) => (
                  <li
                    key={category.id}
                    className={`${
                      activeArtist === category.name ? "active" : ""
                    }`}
                  >
                    <button
                      className="caption-3"
                      onClick={handleArtistClick}
                      data-artist={category.name}
                    >
                      {category.name}
                    </button>
                  </li>
                ))}
              </ul>
            </div>
          </>
        )}
      </div>
    </>
  );
}

export default fetchUserInfo(SellerBlock);
