import React from "react";
import axios from "../../axios";

async function fetchAdminUser() {
  const url = window.location.href;
  const urlParts = url.split("/");
  const urlId = urlParts[urlParts.length - 1];
  const response = await axios.get(`/admin/user/${urlId}`);
  return response.data;
}

function fetchAdminUserInfo(WrappedComponent) {
  return function UserInfoWrapper(props) {
    const [userData, setUserData] = React.useState(null);
    const [isLoading, setIsLoading] = React.useState(true);
    const [isError, setIsError] = React.useState(false);

    React.useEffect(() => {
      const fetchData = async () => {
        try {
          const response = await fetchAdminUser();
          setUserData(response);
        } catch (error) {
          setIsError(true);
        } finally {
          setIsLoading(false);
        }
      };

      fetchData();
    }, []);

    return (
      !isLoading &&
      !isError &&
      userData && (
        <WrappedComponent
          userId={userData._id}
          userName={userData.firstName + " " + userData.lastName}
          userLastName={userData.lastName}
          userFirstName={userData.firstName}
          userEmail={userData.email}
          userRoles={userData.roles.join(", ")}
          userAvatar={userData.avatarURL}
          userToken={userData.artistToken}
          userPer={userData.artistPer}
          userExtendedOrder={userData.isExtendedOrder}
          {...props}
        />
      )
    );
  };
}

export default fetchAdminUserInfo;
