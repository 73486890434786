import React from "react";
import ContentLoader from "react-content-loader";

const Skeleton = ({ isLightMode, ...props }) => {
  const [windowWidth, setWindowWidth] = React.useState(window.innerWidth);

  React.useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return windowWidth > 1340 ? (
    <ContentLoader
      speed={2}
      width={255}
      height={74}
      viewBox="0 0 255 74"
      backgroundColor={isLightMode ? "#f5f5f5" : "#262626"}
      foregroundColor={isLightMode ? "#e2e2e2" : "#333333"}
      {...props}
    >
      <rect x="81" y="46" rx="3" ry="3" width="52" height="8" />
      <circle cx="41" cy="37" r="25" />
      <rect x="81" y="23" rx="3" ry="3" width="163" height="12" />
    </ContentLoader>
  ) : windowWidth > 1200 ? (
    <ContentLoader
      speed={2}
      width={295}
      height={74}
      viewBox="0 0 295 74"
      backgroundColor={isLightMode ? "#f5f5f5" : "#262626"}
      foregroundColor={isLightMode ? "#e2e2e2" : "#333333"}
      {...props}
    >
      <rect x="81" y="46" rx="3" ry="3" width="52" height="8" />
      <circle cx="41" cy="37" r="25" />
      <rect x="81" y="23" rx="3" ry="3" width="193" height="12" />
    </ContentLoader>
  ) : windowWidth > 1000 ? (
    <ContentLoader
      speed={2}
      width={278}
      height={74}
      viewBox="0 0 278 74"
      backgroundColor={isLightMode ? "#f5f5f5" : "#262626"}
      foregroundColor={isLightMode ? "#e2e2e2" : "#333333"}
      {...props}
    >
      <rect x="81" y="46" rx="3" ry="3" width="52" height="8" />
      <circle cx="41" cy="37" r="25" />
      <rect x="81" y="23" rx="3" ry="3" width="173" height="12" />
    </ContentLoader>
  ) : windowWidth > 768 ? (
    <ContentLoader
      speed={2}
      width={302}
      height={74}
      viewBox="0 0 302 74"
      backgroundColor={isLightMode ? "#f5f5f5" : "#262626"}
      foregroundColor={isLightMode ? "#e2e2e2" : "#333333"}
      {...props}
    >
      <rect x="81" y="46" rx="3" ry="3" width="52" height="8" />
      <circle cx="41" cy="37" r="25" />
      <rect x="81" y="23" rx="3" ry="3" width="203" height="12" />
    </ContentLoader>
  ) : windowWidth > 576 ? (
    <ContentLoader
      speed={2}
      width={253}
      height={74}
      viewBox="0 0 253 74"
      backgroundColor={isLightMode ? "#f5f5f5" : "#262626"}
      foregroundColor={isLightMode ? "#e2e2e2" : "#333333"}
      {...props}
    >
      <rect x="81" y="46" rx="3" ry="3" width="52" height="8" />
      <circle cx="41" cy="37" r="25" />
      <rect x="81" y="23" rx="3" ry="3" width="153" height="12" />
    </ContentLoader>
  ) : (
    <ContentLoader
      speed={2}
      width={457}
      height={74}
      viewBox="0 0 457 74"
      backgroundColor={isLightMode ? "#f5f5f5" : "#262626"}
      foregroundColor={isLightMode ? "#e2e2e2" : "#333333"}
      {...props}
    >
      <rect x="81" y="46" rx="3" ry="3" width="52" height="8" />
      <circle cx="41" cy="37" r="25" />
      <rect x="81" y="23" rx="3" ry="3" width="360" height="12" />
    </ContentLoader>
  );
};

export default Skeleton;
