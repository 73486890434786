import React from "react";
import ContentLoader from "react-content-loader";

const Skeleton = ({ isLightMode, ...props }) => {
  const [windowWidth, setWindowWidth] = React.useState(window.innerWidth);

  React.useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return windowWidth > 1340 ? (
    <ContentLoader
      speed={2}
      width={727}
      height={126}
      viewBox="0 0 727 126"
      backgroundColor={isLightMode ? "#f5f5f5" : "#262626"}
      foregroundColor={isLightMode ? "#e2e2e2" : "#333333"}
      {...props}
    >
      <rect x="20" y="30" rx="3" ry="3" width="64" height="15" />
      <rect x="104" y="33" rx="2" ry="2" width="134" height="8" />
      <rect x="20" y="60" rx="3" ry="3" width="410" height="6" />
      <rect x="20" y="74" rx="3" ry="3" width="178" height="6" />
      <rect x="657" y="30" rx="4" ry="4" width="50" height="50" />
      <rect x="0" y="125" rx="5" ry="5" width="727" height="1" />
    </ContentLoader>
  ) : windowWidth > 1200 ? (
    <ContentLoader
      speed={2}
      width={632}
      height={126}
      viewBox="0 0 632 126"
      backgroundColor={isLightMode ? "#f5f5f5" : "#262626"}
      foregroundColor={isLightMode ? "#e2e2e2" : "#333333"}
      {...props}
    >
      <rect x="20" y="30" rx="3" ry="3" width="64" height="15" />
      <rect x="104" y="33" rx="2" ry="2" width="134" height="8" />
      <rect x="20" y="60" rx="3" ry="3" width="410" height="6" />
      <rect x="20" y="74" rx="3" ry="3" width="178" height="6" />
      <rect x="562" y="30" rx="4" ry="4" width="50" height="50" />
      <rect x="0" y="125" rx="5" ry="5" width="632" height="1" />
    </ContentLoader>
  ) : windowWidth > 1000 ? (
    <ContentLoader
      speed={2}
      width={862}
      height={126}
      viewBox="0 0 862 126"
      backgroundColor={isLightMode ? "#f5f5f5" : "#262626"}
      foregroundColor={isLightMode ? "#e2e2e2" : "#333333"}
      {...props}
    >
      <rect x="20" y="30" rx="3" ry="3" width="64" height="15" />
      <rect x="104" y="33" rx="2" ry="2" width="134" height="8" />
      <rect x="20" y="60" rx="3" ry="3" width="410" height="6" />
      <rect x="20" y="74" rx="3" ry="3" width="178" height="6" />
      <rect x="792" y="30" rx="4" ry="4" width="50" height="50" />
      <rect x="0" y="125" rx="5" ry="5" width="862" height="1" />
    </ContentLoader>
  ) : windowWidth > 768 ? (
    <ContentLoader
      speed={2}
      width={624}
      height={126}
      viewBox="0 0 624 126"
      backgroundColor={isLightMode ? "#f5f5f5" : "#262626"}
      foregroundColor={isLightMode ? "#e2e2e2" : "#333333"}
      {...props}
    >
      <rect x="20" y="30" rx="3" ry="3" width="64" height="15" />
      <rect x="104" y="33" rx="2" ry="2" width="134" height="8" />
      <rect x="20" y="60" rx="3" ry="3" width="410" height="6" />
      <rect x="20" y="74" rx="3" ry="3" width="178" height="6" />
      <rect x="554" y="30" rx="4" ry="4" width="50" height="50" />
      <rect x="0" y="125" rx="5" ry="5" width="624" height="1" />
    </ContentLoader>
  ) : windowWidth > 576 ? (
    <ContentLoader
      speed={2}
      width={516}
      height={126}
      viewBox="0 0 516 126"
      backgroundColor={isLightMode ? "#f5f5f5" : "#262626"}
      foregroundColor={isLightMode ? "#e2e2e2" : "#333333"}
      {...props}
    >
      <rect x="20" y="30" rx="3" ry="3" width="64" height="15" />
      <rect x="104" y="33" rx="2" ry="2" width="134" height="8" />
      <rect x="20" y="60" rx="3" ry="3" width="310" height="6" />
      <rect x="20" y="74" rx="3" ry="3" width="178" height="6" />
      <rect x="446" y="30" rx="4" ry="4" width="50" height="50" />
      <rect x="0" y="125" rx="5" ry="5" width="516" height="1" />
    </ContentLoader>
  ) : (
    <ContentLoader
      style={{ width: "100%" }}
      speed={2}
      width={550}
      height="auto"
      viewBox="0 0 550 126"
      backgroundColor={isLightMode ? "#f5f5f5" : "#262626"}
      foregroundColor={isLightMode ? "#e2e2e2" : "#333333"}
      {...props}
    >
      <rect x="20" y="30" rx="3" ry="3" width="64" height="15" />
      <rect x="104" y="33" rx="2" ry="2" width="134" height="8" />
      <rect x="20" y="60" rx="3" ry="3" width="310" height="6" />
      <rect x="20" y="74" rx="3" ry="3" width="178" height="6" />
      <rect x="480" y="30" rx="4" ry="4" width="50" height="50" />
      <rect x="0" y="125" rx="5" ry="5" width="550" height="1" />
    </ContentLoader>
  );
};

export default Skeleton;
