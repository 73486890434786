import React from "react";
import { Routes, Route } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

import Header from "./components/Header";
import Footer from "./components/Footer";
import Preloader from "./components/Preloader";
import Aside from "./components/Aside";
import Title from "./utils/TitlePage";

import Hello from "./pages/Hello";
import Home from "./pages/Home";
import NotFound from "./pages/NotFound";
import Users from "./pages/Admin/Users";
import Options from "./pages/Admin/Setting";

import SignIn from "./pages/Account/SignIn";
import SignUp from "./pages/Account/SignUp";
import Activate from "./pages/Account/Activate";
import Password from "./pages/Account/Password";
import Setting from "./pages/Account/Setting";
import UserEdit from "./pages/Admin/UserSetting";
import { getDeviceId } from "./utils/DeviceId";
import { ThemeProvider } from "./components/Theme/ThemeContext";
import { useTranslation } from "react-i18next";

import "./sass/app.sass";
import {
  checkAuth,
  selectIsAuth,
  selectIsActivated,
  selectIsAdmin,
} from "./redux/slises/auth";

function App({ userId }) {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const isAuth = useSelector(selectIsAuth);
  const isAdmin = useSelector(selectIsAdmin) === "Admin" || "Redactor";
  const isActivated = useSelector(selectIsActivated);
  const [loading, setLoading] = React.useState(true);

  React.useEffect(() => {
    getDeviceId();
  });

  React.useEffect(() => {
    dispatch(checkAuth());
  }, []);

  React.useEffect(() => {
    const timer = setTimeout(() => {
      setLoading(false);
    }, 1000);

    return () => clearTimeout(timer);
  }, []);

  React.useEffect(() => {
    document.documentElement.style.overflow = loading ? "hidden" : "auto";
    document.body.style.overflow = loading ? "hidden" : "auto";
  }, [loading]);

  return (
    <ThemeProvider>
      <Preloader loading={loading} />
      <div className="wrapper">
        <Routes>
          {isAuth && isActivated ? (
            <>
              <Route
                path="/"
                element={
                  <>
                    <Title title={t("title")} />
                    <Header />
                    <div className="main">
                      <div className="container">
                        <Aside />
                        <Home />
                      </div>
                    </div>
                    <Footer />
                  </>
                }
              />
              <Route
                path="/user/edit"
                element={
                  <>
                    <Title title={t("title3") + " » " + t("title")} />
                    <Header />
                    <div className="main">
                      <div className="container">
                        <Aside />
                        <Setting />
                      </div>
                    </div>
                    <Footer />
                  </>
                }
              />
              <Route
                path="*"
                element={
                  <>
                    <Title title={t("title")} />
                    <Header />
                    <NotFound />
                    <Footer />
                  </>
                }
              />
              {isAdmin && (
                <>
                  <Route
                    path="/admin/users"
                    element={
                      <>
                        <Title title={t("title12") + " » " + t("title")} />
                        <Header />
                        <div className="main">
                          <div className="container">
                            <Aside />
                            <Users />
                          </div>
                        </div>
                        <Footer />
                      </>
                    }
                  />
                  <Route
                    path="/admin/users/*"
                    element={
                      <>
                        <Title title={t("title13") + " » " + t("title")} />
                        <Header />
                        <div className="main">
                          <div className="container">
                            <Aside />
                            <UserEdit />
                          </div>
                        </div>
                        <Footer />
                      </>
                    }
                  />
                  <Route
                    path="/admin/setting"
                    element={
                      <>
                        <Title title={t("title10") + " » " + t("title")} />
                        <Header />
                        <div className="main">
                          <div className="container">
                            <Aside />
                            <Options />
                          </div>
                        </div>
                        <Footer />
                      </>
                    }
                  />
                </>
              )}
            </>
          ) : isAuth ? (
            <>
              <Route
                path="/"
                element={
                  <>
                    <Title title={t("title20") + " » " + t("title")} />
                    <Activate />
                  </>
                }
              />
              <Route
                path="/user/edit"
                element={
                  <>
                    <Title title={t("title3") + " » " + t("title")} />
                    <Header />
                    <NotFound />
                    <Footer />
                  </>
                }
              />
              <Route
                path="*"
                element={
                  <>
                    <Title title={t("title")} />
                    <Header />
                    <NotFound />
                    <Footer />
                  </>
                }
              />
            </>
          ) : (
            <>
              <Route
                path="/"
                element={
                  <>
                    <Title title={t("title")} />
                    <Hello />
                  </>
                }
              />
              <Route
                path="/account/login"
                element={
                  <>
                    <Title title={t("title21") + " » " + t("title")} />
                    <SignIn />
                  </>
                }
              />
              <Route
                path="/account/register"
                element={
                  <>
                    <Title title={t("title18") + " » " + t("title")} />
                    <SignUp />
                  </>
                }
              />
              <Route
                path="/account/forgot-password"
                element={
                  <>
                    <Title title={t("title19") + " » " + t("title")} />
                    <Password />
                  </>
                }
              />
              <Route
                path="/account/reset-password"
                element={
                  <>
                    <Title title={t("title19") + " » " + t("title")} />
                    <Password />
                  </>
                }
              />
              <Route
                path="/user/edit"
                element={
                  <>
                    <Title title={t("title3") + " » " + t("title")} />
                    <Header />
                    <NotFound />
                    <Footer />
                  </>
                }
              />
              <Route
                path="*"
                element={
                  <>
                    <Title title={t("title")} />
                    <Header />
                    <NotFound />
                    <Footer />
                  </>
                }
              />
            </>
          )}
        </Routes>
      </div>
    </ThemeProvider>
  );
}

export default App;
