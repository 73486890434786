import React from "react";
import { Link, useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";

function ButtonBack(props) {
  const navigate = useNavigate();
  const handleGoBack = () => {
    navigate(-1);
  };
  const { t } = useTranslation();

  return (
    <Link to="#" onClick={handleGoBack}>
      <i className="icon-arrow-left"></i>
      <span className="caption-2">{t("btn10")}</span>
    </Link>
  );
}

export default ButtonBack;
