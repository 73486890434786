import React from "react";
import { useDispatch } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";

import { logout } from "../../redux/slises/auth";
import fetchUserInfo from "../User";

function Login({ userName, userRoles, userAvatar, userRolesRus }) {
  const [dropdownOpened, setDropdownOpened] = React.useState(false);
  const dropdownRef = React.useRef(null);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { t } = useTranslation();

  const handleLogout = () => {
    dispatch(logout());
    navigate("/");
  };

  const toggleDropdown = () => {
    setDropdownOpened((prev) => !prev);
  };

  const handleClickOutsideDropdown = (event) => {
    if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
      setDropdownOpened(false);
    }
  };

  React.useEffect(() => {
    document.addEventListener("click", handleClickOutsideDropdown);
    return () => {
      document.removeEventListener("click", handleClickOutsideDropdown);
    };
  }, []);

  return (
    <>
      <div className="account-info">
        <h3>{userName}</h3>
        <p className="group caption-4">{userRolesRus}</p>
      </div>
      <div className="account-btn">
        <div
          onClick={toggleDropdown}
          className="account-drop"
          ref={dropdownRef}
        >
          <div className="account-avatar">
            <img src={userAvatar} height="500" width="500" alt="LeksKokorin" />
          </div>
        </div>
        <div
          className={dropdownOpened ? "account-arrow show" : "account-arrow"}
          id="account-arrow"
        >
          <i className="icon-arrow-down"></i>
        </div>
        {dropdownOpened && (
          <div id="account-drop" className="dropdown">
            <ul>
              {userRoles === "Admin" && (
                <>
                  <li className="menu-item admin-mob">
                    <Link to={`/admin/users`}>
                      <div className="menu-icon">
                        <i className="icon-users"></i>
                      </div>
                      <span className="button-1">{t("btn2")}</span>
                    </Link>
                  </li>
                  <li className="menu-item admin-mob">
                    <Link to={`/admin/setting`}>
                      <div className="menu-icon">
                        <i className="icon-setting"></i>
                      </div>
                      <span className="button-1">{t("btn3")}</span>
                    </Link>
                  </li>
                </>
              )}
              {userRoles === "Redactor" && (
                <>
                  <li className="menu-item admin-mob">
                    <Link to={`/admin/users`}>
                      <div className="menu-icon">
                        <i className="icon-users"></i>
                      </div>
                      <span className="button-1">{t("btn2")}</span>
                    </Link>
                  </li>
                  <li className="menu-item admin-mob">
                    <Link to={`/admin/setting`}>
                      <div className="menu-icon">
                        <i className="icon-setting"></i>
                      </div>
                      <span className="button-1">{t("btn3")}</span>
                    </Link>
                  </li>
                </>
              )}
              <li className="menu-item">
                <Link to={`/user/edit`}>
                  <div className="menu-icon">
                    <i className="icon-edit"></i>
                  </div>
                  <span className="button-1">{t("btn11")}</span>
                </Link>
              </li>
              <li className="menu-item">
                <Link to="/" onClick={handleLogout}>
                  <div className="menu-icon">
                    <i className="icon-logout"></i>
                  </div>
                  <span className="button-1">{t("btn9")}</span>
                </Link>
              </li>
            </ul>
          </div>
        )}
      </div>
    </>
  );
}

export default fetchUserInfo(Login);
