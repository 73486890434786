const ruJSON = {
  title: "VSRAPBOY - Личный кабинет партнера",
  title2: "Последние заказы",
  title3: "Настройки профиля",
  title4: "Ой, кажется, вы потерялись!",
  title5: "ONLY FOR",
  title6: "Личный кабинет партнеров и артистов VSRAP",
  title7: "Мерчендайз",
  title8: "Дистрибуция",
  title9: "Интеграции",
  title10: "Общие настройки",
  title11: "Настройка процентов выручки",
  title12: "Все пользователи",
  title13: "Редактирование пользователя",
  title14: "ОТЧЕТЫ ПО ПРОДАЖАМ",
  title15: "ТОВАРЫ",
  title16: "ВЫРУЧКА",
  title17: "ПРИБЫЛЬ",
  title18: "Регистрация аккаунта",
  title19: "Восстановление пароля",
  title20: "Активация аккаунта",
  title21: "Вход в аккаунт",

  text: "Страница в данный момент не доступна, или у вас нет доступа к данном разделу.",
  text2: "Отчеты о продажах, подсчет прибыли",
  text3: "Статистика музыкальных релизов",
  text4: "Вся рекламная аналитика",
  text5: "Ваш аккаунт еще не активирован!",
  text6:
    "Для активации аккаунта перейдите по ссылке в письме, отправленному на:",

  developer: "Сделано с любовью в",

  btn: "Мерчендайз",
  btn2: "Пользователи",
  btn3: "Настройки",
  btn4: "vsrap.shop",
  btn5: "Дистрибуция",
  btn6: "Интеграции",
  btn7: "Посты",
  btn8: "Войти",
  btn9: "Выйти из профиля",
  btn10: "Вернуться назад",
  btn11: "Настройки профиля",
  btn12: "Сохранить изменения",
  btn13: "Вернуться на главную",
  btn14: "Далее",
  btn15: "Все",
  btn16: "Админы",
  btn17: "Редакторы",
  btn18: "Артисты",
  btn19: "Пользователи",
  btn20: "Да",
  btn21: "Нет",
  btn22: "Удалить аккаунт",
  btn23: "Войти в аккаунт",
  btn24: "Регистрация",
  btn25: "Забыли пароль?",
  btn26: "Зарегистрироваться",
  btn27: "Восстановить пароль",
  btn28: "Отправить письмо заново",

  label: "E-mail:",
  label2: "ID артиста:",
  label3: "Имя:",
  label4: "Фамилия:",
  label5: "Старый пароль:",
  label6: "Новый пароль:",
  label7: "Стандартный процент выручки:",
  label8: "Средний процент выручки:",
  label9: "Высокий процент выручки:",
  label10: "Стандартный порог выручки:",
  label11: "Высокий порог выручки:",
  label12: "Группа:",
  label13: "Отображать блок выручки в отчетах о продажах",
  label14: "Процент выручки:",
  label15: "ID коллекции:",

  placeholder: "Например: info@mail.ru",
  placeholder2: "Например: IVANOV",
  placeholder3: "Например: Иван",
  placeholder4: "Например: Иванов",
  placeholder5: "",
  placeholder6: "",
  placeholder7: "Например: 20",
  placeholder8: "Например: 22",
  placeholder9: "Например: 25",
  placeholder10: "Поиск пользователя",
  placeholder11: "Ваш E-mail",
  placeholder12: "Пароль",
  placeholder13: "Имя",
  placeholder14: "Фамилия",
  placeholder15: "Повторите пароль",
  placeholder16: "Например: 1500000",
  placeholder17: "Например: 3000000",

  role_admin: "Админ",
  role_editor: "Редактор",
  role_artist: "Артист",

  info: "Сохраняем...",
  info2: "Удаляем...",
  info3: "Вы точно хотите удалить аккаунт?",
  info4: "Нажмите на блок чтобы увидеть выручку",
  info5: "Нажмите на блок чтобы увидеть прибыль",

  error: "Аватар не загружен",
  error2: "Аватар не удален",
  error4: "Изменения не обнаружены",
  error5: "Произошла ошибка при сохранении изменений",
  error6: "Пользователей по вашему запросу не найдено",
  error7: "Не удалось получить список пользователей",
  error8: "Произошла ошибка при удалении аккаунта",
  error10: "Ошибка при получении уникального идентификатора устройства:",
  error11: "Данные по продажам не были получены",
  error12: "Заказы на указанную дату не найдены",
  error13: "Вход не произведен, возможно введены неверные данные аккаунта",
  error14: "Введите имя",
  error15: "Имя слишком длинное",
  error16: "Введите фамилию",
  error17: "Фамилия слишком длинная",
  error18: "Введите адрес электронной почты",
  error19: "Введите корректный адрес электронной почты",
  error20: "Придумайте пароль",
  error21: "Длина пароль минимум 6 символов",
  error22: "Повторно введите пароль",
  error23: "Пароли должны совпадать",
  error24: "Письмо уже отправлено в течение последних 5 минут!",
  error25: "Письмо отправляется один раз в 5 минут",
  error26: "Пользователь с таким email не найден",

  success: "Аватар загружен!",
  success2: "Аватар удален!",
  success4: "Изменения сохранены",
  success5: "Ссылка для сброса пароля успешно отправлена!",
  success6: "Ссылка для сброса пароля отправлена!",

  order2: "Возврат",
  order3: "Розница",
  order4: "шт",
};

export default ruJSON;
